import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Card, Typography, Tabs, Tab, Button } from "@mui/material";
import "react-phone-input-2/lib/bootstrap.css";
import CompanyProfile from "../../features/company/companyProfile";
import CompanyDocumentation from "../../features/company/companyDocsById";
import { useNavigate, useParams } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddCompany = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { tab } = useParams();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tab) {
      setValue(Number(tab))
    }
  }, [tab])

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {id ? "Edit Company Details" : "Add Company Details"}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-company");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Documentation" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <CompanyProfile setValue={setValue} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CompanyDocumentation />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default AddCompany;
