import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanySubscriptionDetails = () => {
  const navigate = useNavigate();
  const UserPlanFeatures = [
    { id: 1, name: "50 posts per hour " },
    { id: 2, name: "unlimited chats with clients " },
    { id: 3, name: "24*7 customer support " },
   
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Company Subscription Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-company-subscription");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    {/* <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Name
                    </Typography> */}
                    <Grid container spacing={3}>
                     
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Plan type</Typography>
                          <Typography component="p">Yearly</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5"> Maximum no of agents can be added</Typography>
                          <Typography component="p">20</Typography>
                        </Box>
                      </Grid>
                    
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p"> $499/yr</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
  <Box>
    <Typography component="h5">Features</Typography>
    {UserPlanFeatures.map((feature:any) => (
      <Typography key={feature.id} component="p" sx={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px', width: '5px', height: '5px', borderRadius: '50%', backgroundColor: 'black', display: 'inline-block' }}></span>
        {feature.name}
      </Typography>
    ))}
  </Box>
</Grid>


                     
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

 
      </div>
    </div>
  );
};

export default CompanySubscriptionDetails;
