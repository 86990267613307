import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import Messages from "../../features/users/Messages";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserPropertyDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  // Function to generate random user details
  const generateRandomUser = () => {
    const names = ["John Doe", "Jane Doe", "Alice Smith", "Bob Johnson"];
    const ages = [25, 30, 35, 40];
    const emails = ["john@example.com", "jane@example.com", "alice@example.com", "bob@example.com"];
    const addresses = ["123 Main St", "456 Elm St", "789 Oak St", "101 Pine St"];

    const randomIndex = Math.floor(Math.random() * names.length);

    return {
      name: 4,
      age: "Chandigarh",
      email: "24*7",

    };
  };

  // Get random user details
  const randomUser = generateRandomUser();

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">User Property Listing Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/property-listing");
            }}
          >
            Back
          </Button>
        </div>

        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Image</Typography>
                      <Grid item lg={2} md={2} sm={6} xs={12}>
                        <figure className="profile_img">
                          <img style={{ borderRadius: 0 }} src="/static/images/house.jpg" alt="" />
                        </figure>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Name</Typography>
                      <Typography component="p">56 Green Bank , London</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">$1200</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">  Category</Typography>
                      <Typography component="p">Villa</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Type</Typography>
                      <Typography component="p">Rent</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Property Owned By</Typography>
                      <Typography component="p">User1</Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={4} md={6} sm={6} xs={12}>

                      </Grid> */}
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">Thank you for checking out Camping above the clouds Camping. We have five primitive camping spots providing privacy and serenity.</Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12}>
                        <Box>
                          <Typography component="h5">Specifications</Typography>
                          <Typography component="p">
                            Rooms: {randomUser.name}<br />
                            Location: {randomUser.age}<br />
                            Availability: {randomUser.email}<br />
                          
                          </Typography>
                        </Box>
                      </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <div className="dashboard" style={{ paddingTop: 35 }}>
          <h2 className="mn_hdng">Property Chat Details</h2>
        </div>
        <Messages />
      </div>
      {/* </div> */}
    </div>
  );
};

export default UserPropertyDetails;
