import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPerformanceInsights: builder.query<
      CommonResponseType & { data: any },
      {
        leadsFilterType?: string;
        leadType?: string;
        type: number;
        agentInsightType?: number;
        id?: string;
        superAgent?: boolean;
        page?: number;
        search?: string;
        limit?: number;
        startDate?:string | Date | undefined;
        endDate?:string | Date | undefined;
      }
    >({
      query: ({
        leadsFilterType,
        leadType,
        agentInsightType,
        type,
        id,
        superAgent,
        page,
        limit,
        search,
        startDate,
        endDate
      }) => {
        let url = `${END_POINTS.leadsInsightPerformance}`;
        //type1 overall
        //type2 listing
        //type3 agents
        //type4 leads
        const user = id ? `/user/${id}/` : "";
        const superAgentData = superAgent ? `/user/` : "";
        console.log("id service :", id);
        if (type == 1) {
          url += `/company?startDate=${startDate}&endDate=${endDate}`;
        } else if (type == 2) {
        } else if (type == 3) {
          url += `/agents?startDate=${startDate}&endDate=${endDate}`;
        } else {
          url += `/leads?startDate=${startDate}&endDate=${endDate}`;
        }
        if (leadsFilterType) {
          url += `?filterType=${leadsFilterType}`;
          if (leadType) {
            url += `&leadType=${leadType}`;
          }
        }
        if (agentInsightType) {
          url += `?agentInsightType=${agentInsightType}`;
        }
        const pageLimitSearch = page
          ? `&page=${page}&search=${search}&limit=${limit}`
          : "";
        return {
          url: `${superAgent ? superAgentData : user}${url}${pageLimitSearch}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useLazyGetPerformanceInsightsQuery } = authApi;
