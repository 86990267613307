import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import MainContainer from "./layout/MainContainer";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      
      <Route path="/dashboard" element={<MainContainer><Pages.DashBoardPage /></MainContainer>} />
      <Route
        path="/listing-performance/dashboard"
        element={<MainContainer><Pages.ListingPerformanceDashboard /></MainContainer>}
      />
      <Route path="/agent-insights" element={<MainContainer><Pages.ManageRecipes /></MainContainer>} />
      <Route path="/profile" element={<MainContainer><Pages.Profile /></MainContainer>} />
      <Route path="/manage-users" element={<MainContainer><Pages.ManageUsers /></MainContainer>} />
      <Route path="/manage-users/details/:_id" element={<MainContainer><UserDetails /></MainContainer>} />
      <Route path="/manage-users/usersform" element={<MainContainer><UsersForm /></MainContainer>} />
      <Route path="/manage-users/usersform/:id" element={<MainContainer><UsersForm /></MainContainer>} />
      <Route path="/manage-users/edit/chat" element={<MainContainer><Pages.ChatDetails /></MainContainer>} />
      <Route
        path="/manage-users/token/details"
        element={<MainContainer><Pages.userTokenHistoryDetails /></MainContainer>}
      />
      <Route
        path="/manage-users/property/details"
        element={<MainContainer><Pages.UserPropertyDetails /></MainContainer>}
      />

      <Route
        path="/manage-users/property/details/UserChatInbox/:id"
        element={<MainContainer><Pages.UserChatInbox /></MainContainer>}
      />

      <Route
        path="/manage-users/property/details/ChatInbox/:id"
        element={<MainContainer><Pages.PropertyChatInbox /></MainContainer>}
      />
      <Route path="/analytics" element={<MainContainer><Pages.Analytics /></MainContainer>} />

      <Route path="/cms" element={<MainContainer><Pages.ManageCms /></MainContainer>} />

      <Route
        path="/manage-notifications"
        element={<MainContainer><Pages.ManageNotifications /></MainContainer>}
      />
      <Route
        path="/manage-notifications/add"
        element={<MainContainer><Pages.AddNotification /></MainContainer>}
      />
      <Route
        path="/recieved-notifications"
        element={<MainContainer><Pages.RecievedNotifications /></MainContainer>}
      />

      <Route path="/manage-agents" element={<MainContainer><Pages.ManageRecipes /></MainContainer>} />
      <Route path="/manage-agents/edit/:id" element={<MainContainer><Pages.AddAgents /></MainContainer>} />
      <Route path="/manage-agents/add" element={<MainContainer><Pages.AddAgents /></MainContainer>} />
      <Route
        path="/manage-agents/details/:_id"
        element={<MainContainer><Pages.AgentDetail /></MainContainer>}
      />
      <Route path="/manage-agents/edit/id" element={<MainContainer><Pages.AddRecipes /></MainContainer>} />
      <Route
        path="/manage-agents/edit/chat"
        element={<MainContainer><Pages.AgentChatDetails /></MainContainer>}
      />
      <Route
        path="/manage-agent/token/details"
        element={<MainContainer><Pages.AgentTokenHistoryDetails /></MainContainer>}
      />
      <Route
        path="/manage-agents/property-listing/details/:_id"
        element={<MainContainer><Pages.AgentNowPropertyDetails /></MainContainer>}
      />

      <Route
        path="/manage-agents/property/details/AgentChatInbox/:id"
        element={<MainContainer><Pages.AgentChatInbox /></MainContainer>}
      />
      <Route
        path="/manage-agents//:_id"
        element={<MainContainer><Pages.AgentNowPropertyDetails /></MainContainer>}
      />
      <Route path="/manage-company" element={<MainContainer><Pages.ManageCompany /></MainContainer>} />
      <Route path="/manage-company/add" element={<MainContainer><Pages.AddCompany /></MainContainer>} />
      <Route
        path="/manage-company/add/:id/:tab"
        element={<MainContainer><Pages.AddCompany /></MainContainer>}
      />
      <Route
        path="/manage-company/details/:_id"
        element={<MainContainer><Pages.CompanyDetails /></MainContainer>}
      />
      <Route path="/manage-company/edit/:id" element={<MainContainer><Pages.AddCompany /></MainContainer>} />
      <Route path="/manage-company-requests" element={<MainContainer><Pages.ManageCompanyRequests /></MainContainer>} />
      <Route
        path="/manage-company-requests/details/:_id"
        element={<MainContainer><Pages.CompanyRequestDetails /></MainContainer>}
      />
      <Route path="/manage-property-requests" element={<MainContainer><Pages.ManagePropertyRequests /></MainContainer>} />
      <Route
        path="/manage-property-requests/details/:_id"
        element={<MainContainer><Pages.PropertyRequestDetails /></MainContainer>}
      />
      <Route
        path="/company/property-listing/details/:_id"
        element={<MainContainer><Pages.CompanyPropertyDetails /></MainContainer>}
      />

      <Route
        path="/manage-company/details/chatInbox/:id"
        element={<MainContainer><Pages.CompanyChatInbox /></MainContainer>}
      />
      <Route path="/manage-ads" element={<MainContainer><Pages.ManageAds /></MainContainer>} />
      <Route path="/manage-ads/add" element={<MainContainer><Pages.AddAds /></MainContainer>} />
      <Route path="/manage-ads/edit/:_id" element={<MainContainer><Pages.AddAds /></MainContainer>} />
      <Route path="/manage-ads/details/:_id" element={<MainContainer><Pages.AdsDetails /></MainContainer>} />

      <Route path="/manage-banners" element={<MainContainer><Pages.ManageBanners /></MainContainer>} />
      <Route path="/manage-banners/add" element={<MainContainer><Pages.AddBanners /></MainContainer>} />
      <Route path="/manage-banners/edit/:_id" element={<MainContainer><Pages.AddBanners /></MainContainer>} />
      <Route
        path="/manage-banners/details/:_id"
        element={<MainContainer><Pages.BannersDetails /></MainContainer>}
      />

      <Route path="/property-listing" element={<MainContainer><Pages.ManageProperty /></MainContainer>} />
      <Route path="/property-listing/add" element={<MainContainer><Pages.AddProperty /></MainContainer>} />
      <Route
        path="/property-listing/edit/:_id"
        element={<MainContainer><Pages.AddProperty /></MainContainer>}
      />
      <Route
        path="/property-listing/details/:_id"
        element={<MainContainer><Pages.PropertyDetails /></MainContainer>}
      />

      <Route path="/manage-chat" element={<MainContainer><Pages.ManageChat /></MainContainer>} />
      <Route
        path="/manage-chat/details/:id"
        element={<MainContainer><Pages.IndexChatDetails /></MainContainer>}
      />

      <Route path="/manage-amenities" element={<MainContainer><Pages.ManageAmenities /></MainContainer>} />
      <Route path="/manage-amenities/add" element={<MainContainer><Pages.AddAmenities /></MainContainer>} />
      <Route
        path="/manage-amenities/edit/:_id"
        element={<MainContainer><Pages.AddAmenities /></MainContainer>}
      />
      <Route
        path="/manage-amenities/details/:_id"
        element={<MainContainer><Pages.CategoryDetails /></MainContainer>}
      />

      <Route path="/manage-reels" element={<MainContainer><Pages.ManageReels /></MainContainer>} />
      <Route
        path="/manage-reels/details/:_id"
        element={<MainContainer><Pages.ReelsDetails /></MainContainer>}
      />
      <Route
        path="/manage-reels/agents/:_id"
        element={<MainContainer><Pages.AgentReelById /></MainContainer>}
      />
      <Route
        path="/manage-reels/company/:_id"
        element={<MainContainer><Pages.CompanyReelById /></MainContainer>}
      />

      <Route
        path="/manage-propertyType"
        element={<MainContainer><Pages.ManagePropertyType /></MainContainer>}
      />
      <Route
        path="/manage-propertyType/add"
        element={<MainContainer><Pages.AddPropertyType /></MainContainer>}
      />
      <Route
        path="/manage-propertyType/edit/:_id"
        element={<MainContainer><Pages.AddPropertyType /></MainContainer>}
      />

      <Route path="/manage-categories" element={<MainContainer><Pages.ManageCategory /></MainContainer>} />
      <Route path="/manage-categories/add" element={<MainContainer><Pages.AddCategory /></MainContainer>} />

      <Route
        path="/manage-categories/edit/:_id"
        element={<MainContainer><Pages.AddCategory /></MainContainer>}
      />
      <Route
        path="/manage-categories/details/:_id"
        element={<MainContainer><Pages.CategoryDetails /></MainContainer>}
      />

      <Route path="/manage-revenue" element={<MainContainer><Pages.ManageRevenue /></MainContainer>} />
      <Route path="/manage-insights" element={<MainContainer><Pages.ManageSuperDashboard /></MainContainer>} />

      <Route path="/settings" element={<MainContainer><Pages.Settings /></MainContainer>} />

      <Route
        path="/manage-propertyStatus"
        element={<MainContainer><Pages.ManagePropertyStatus /></MainContainer>}
      />
      <Route
        path="/manage-propertyStatus/add"
        element={<MainContainer><Pages.AddPropertyStatus /></MainContainer>}
      />
      <Route
        path="/manage-propertyStatus/edit/:_id"
        element={<MainContainer><Pages.AddPropertyStatus /></MainContainer>}
      />

      {/* fasting */}

      <Route
        path="/manage-subscription"
        element={<MainContainer><Pages.ManageSubscription /></MainContainer>}
      />
      <Route
        path="/manage-subscription/details/:_id"
        element={<MainContainer><Pages.SubscriptionDetails /></MainContainer>}
      />
      <Route
        path="/manage-subscription/add"
        element={<MainContainer><Pages.Addsubscription /></MainContainer>}
      />
      <Route
        path="/manage-subscription/edit/:_id"
        element={<MainContainer><Pages.Addsubscription /></MainContainer>}
      />

      <Route
        path="/manage-company-subscription"
        element={<MainContainer><Pages.ManageCompanySubscription /></MainContainer>}
      />
      <Route
        path="/manage-company-subscription/details"
        element={<MainContainer><Pages.CompanySubscriptionDetails /></MainContainer>}
      />
      <Route
        path="/manage-company-subscription/add"
        element={<MainContainer><Pages.AddCompanySubscription /></MainContainer>}
      />

      <Route path="/manage-boost" element={<MainContainer><Pages.ManageBoost /></MainContainer>} />
      <Route
        path="/manage-boost/details/:_id"
        element={<MainContainer><Pages.BoostDetails /></MainContainer>}
      />
      <Route path="/manage-boost/add" element={<MainContainer><Pages.AddBoost /></MainContainer>} />
      <Route path="/manage-boost/edit/:id" element={<MainContainer><Pages.AddBoost /></MainContainer>} />

      <Route path="/manage-emails" element={<MainContainer><Pages.ManageEmails /></MainContainer>} />
      <Route path="/manage-listing" element={<MainContainer><Pages.ManageListing /></MainContainer>} />

      <Route path="/manage-feedbacks" element={<MainContainer><Pages.ManageFeedbacks /></MainContainer>} />
      <Route path="/wishlist-details" element={<MainContainer><Pages.WishlistPropertyDetails /></MainContainer>} />
      <Route path="/user-chat-details/:userId/:id" element={<MainContainer><Pages.userChatInboxDetails /></MainContainer>} />
      <Route path="/user-email-details/:userId" element={<MainContainer><Pages.UserEmailInboxDetails /></MainContainer>} />
      < Route path="/manage-enqueries" element={<MainContainer><Pages.ManageEnqeries /></MainContainer>} />
      < Route path="/manage-newsletter-emails" element={<MainContainer><Pages.ManageNewsLetterEmails /></MainContainer>} />
      < Route path="/manage-property-reports" element={<MainContainer><Pages.ManagePropertyReports /></MainContainer>} />
      < Route path="/manage-property-reports/details/:id" element={<MainContainer><Pages.PropertyReqDetails /></MainContainer>} />
      < Route path="/listing-action-tracker" element={<MainContainer><Pages.ListingActionTracker /></MainContainer>} />
      < Route path="/listing-action-tracker/details/:id" element={<MainContainer><Pages.PropertyDetailsActionTraker /></MainContainer>} />
    </Routes>
  );
};

export default Routing;
