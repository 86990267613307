import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody, CompanyRequest } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};

type Res = {
    totalCount(totalCount: any): unknown;
    adminRequests: CompanyRequest[]
    count: number
}

export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getAllRequest: builder.query<CommonResponseType & { data: Res }, {
            page?: number; search?: string; limit: number, status?: number,
            range?: {
                from: string | undefined;
                to: string | undefined;
            };
        }>({
            query: ({ page, search, limit, status, range }) => {
                let url = `${END_POINTS.adminRequests}?status=${status}&page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}`;
                return {
                    url: url,
                    method: "GET",
                };
            },
            keepUnusedDataFor: 300,
            providesTags: ["GETCOMPANYREQ"]
        }),
        getRequestById: builder.query<CommonResponseType & { data: CompanyRequest }, { userId: any }>
            ({
                query: ({ userId }) => ({
                    url: `${END_POINTS.adminRequests}/${userId}`,
                    method: "GET"
                })
            }),

        PostReason: builder.mutation<
            CommonResponseType & { data: any },
            { body: CommonBody, id: string }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.adminRequests}/${id}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: ["GETCOMPANYREQ"]
        }),


    }),
});

export const {
    useGetAllRequestQuery,
    useLazyGetRequestByIdQuery,
    usePostReasonMutation
} = authApi;
