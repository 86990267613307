

import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetSpecificChatByIdQuery } from "../../services/chat";
import { errorToast } from "../../helpers";

const CompanyChatInbox = () => {
  const { id } = useParams();
  const [rows, setRows] = useState<any[]>([]);
  const [fetchApi, { isLoading }] = useLazyGetSpecificChatByIdQuery();
  const navigate = useNavigate();

  const getById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      setRows(response?.data?.chats || []);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  useEffect(() => {
    getById(id);
  }, [id]);

  const shouldShowTimestamp = (currentIndex: number) => {
    if (currentIndex === 0) return true;
    const currentTime = new Date(rows[currentIndex].createdAt);
    const previousTime = new Date(rows[currentIndex - 1].createdAt);
    const timeDifference = (currentTime.getTime() - previousTime.getTime()) / (1000 * 60 * 60);
    return timeDifference > 6;
  };


  const distinctSenderTypes = Array.from(new Set(rows.map(chat => chat.senderType)));

  const incomingType = distinctSenderTypes.includes(3) ? 3 : distinctSenderTypes.find(type => type !== 4);
  const outgoingType = distinctSenderTypes.find(type => type !== incomingType);

  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Chat Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-company");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards chat_card">
          <CardContent sx={{ p: 1 }}>
            <div className="main">
              <p className="prop_name">Property Name: <strong>{rows?.length > 0 && rows[0]?.properties?.name}</strong></p>
              <div className="heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {rows?.length > 0 && (
                  <>
                    <div className="userDetail" style={{ display: 'flex' }}>
                      <figure>
                        <img src={rows[0]?.receiverId?.image} alt={rows[0]?.receiverId?.name} />
                      </figure>
                      <div className="username">
                        <h3>{rows[0]?.receiverId?.name}</h3>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="chat-div">
                <div className="chats_lst">
                  {rows.map((chat, index) => (
                    <div key={chat?._id}>
                      {shouldShowTimestamp(index) && (
                        <div className="time">
                          <p>{new Date(chat?.createdAt).toLocaleString()}</p>
                        </div>
                      )}
                      <div className={chat.senderType === outgoingType ? "out-going" : "incoming"}>
                        <p>{chat?.message}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CompanyChatInbox;






