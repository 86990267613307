import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};



interface CategoryResponse extends CommonResponseType {
  data: any;
}

interface QueryParams {
  userId?: any;
  page?: any
}

export const ReelsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getAllReels: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number, type?: string, startDate: string | Date, endDate: string | Date }>({
      query: ({ page, search, limit, type,startDate,endDate }) => {
        let url = `${END_POINTS.reels}?page=${page}&search=${search}&limit=${limit}&type=${type}&startDate=${startDate}&endDate=${endDate}`;

        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor:300,
      providesTags: ["GETREELS"],
    }),


    getSpecificReelsById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId, page }) => {
        let url = `${END_POINTS.reels}/${userId}`;
        if (page !== undefined) {
          url += `?page=${page}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),


    deleteReelsById: builder.mutation<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.reels}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags:["GETREELS"]
    }),

  }),
});

export const {
  useLazyGetAllReelsQuery,
  useGetAllReelsQuery,
  useLazyGetSpecificReelsByIdQuery,
  useDeleteReelsByIdMutation
} = ReelsApi;
