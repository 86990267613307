import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../helpers/toast";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";

import { useDeleteByIdpropertyTypeMutation, useGetAllpropertyTypeQuery, useLazyGetAllpropertyTypeQuery } from "../../services/propertyType";
import { PropertyType } from "../../types/General";
const ManagePropertyType = () => {

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [rows, setRows] = useState<PropertyType[]>([]);
  const [fetchAll, { isLoading }] = useLazyGetAllpropertyTypeQuery();
  const [deleteType] = useDeleteByIdpropertyTypeMutation();
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };


  const {data,isError,isSuccess,isLoading:load}=useGetAllpropertyTypeQuery({
    page: page, search: debouncedSearchTerm.trim(), limit: limit
  })
  const handleDelete = async (userId: any) => {
    try {
      const response = await deleteType({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Property Type deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };


  
  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRows(res?.propertyType || []);
      setTotalCount(res?.count);
    }
  }, [isSuccess, data]);



  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Property Type</h1>
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar2
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            <Box className="cards_header_right">

              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-propertyType/add");
                }}
              >
                <AddIcon />
                Add Property Type
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>

                  <TableCell>Property Type</TableCell>
                  {/* <TableCell>Tokens</TableCell> */}
                  <TableCell>Premium</TableCell>
                  <TableCell>Featured</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length == 0 ? (<TableRow>
                  <TableCell colSpan={12} align="center">
                    No property type found
                  </TableCell>
                </TableRow>) :
                  (rows.map((row: any, index: any) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center"> {(page - 1) * 10 + index + 1}</TableCell>

                      <TableCell>{row?.name || "-"}</TableCell>
                      {/* <TableCell>{row?.settings?.token || '-'}</TableCell> */}
                      <TableCell>{row?.premiumPercentage || '-'}</TableCell>
                      <TableCell>{row?.featuredPercentage || '-'}</TableCell>

                      <TableCell>
                        <Box className="table_actions">

                          <IconButton
                            onClick={() =>
                              navigate(`/manage-propertyType/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {/* {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )} */}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Property Type"
      />
    </div>
  );
};

export default ManagePropertyType;
