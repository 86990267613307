import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string;
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser = {
  page: number;
  search: string;
};
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query<
      CommonResponseType & { data: any },
      { page?: number; search?: string; limit: number; startDate?: Date | string; endDate?: Date | string }
    >({
      query: ({ page, search, limit, startDate, endDate }) => {
        // let url = END_POINTS.user;
        let url = `${END_POINTS.user}?role=4&page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;

        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getPendingCompany: builder.query<
      CommonResponseType & { data: any },
      {
        page?: number; search?: string; limit: number, isAdminAprooved: number,range?: {
          from: string | undefined;
          to: string | undefined;
        }, 
      }
    >({
      query: ({ page, search, limit, range, isAdminAprooved }) => {
        let url = `${END_POINTS.user}?role=5&isAdminAprooved=${isAdminAprooved}&page=${page}&search=${search}&limit=${limit}&startDate=${range?.from}&endDate=${range?.to}`;
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 300,
      providesTags: ["GETCOMPANY"]

    }),



    getPendingAgent: builder.query<
      CommonResponseType & { data: any },
      { page?: number; search?: string; limit: number, startDate?: string | Date, endDate?: string | Date, isAdminAprooved: number, parentId?: string, }
    >({
      query: ({ page, search, limit, startDate, endDate, isAdminAprooved, parentId }) => {
        let url = `${END_POINTS.user}?role=3&isAdminAprooved=${isAdminAprooved}&page=${page}&search=${search}&limit=${limit}&startDate=${startDate}&endDate=${endDate}${parentId ? `&parentAgentId=${parentId}` : ""}`;

        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 300,
      providesTags: ["GETAGENT"]
    }),


    getSpecificUserById: builder.query<
      CommonResponseType & { data: any },
      { userId: any, detailsType: number }
    >({
      query: ({ userId, detailsType }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=${detailsType}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    GetReelByAgent: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=6`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    GetAgentCriteria: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=12`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    GetAgentInsights: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=7`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    getSpecificCompanyAllAgentsById: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=2`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    getSpecificCompanyPropertyById: builder.query<
      CommonResponseType & { data: any },
      { userId: any, listingType?: number | string, propertyStatus?: number | string }
    >({
      query: ({ userId, propertyStatus, listingType }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=3&limit=10&propertyStatus=${propertyStatus}&listingType=${listingType}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    getTokenListing: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=5&limit=10`;
        return {
          url,
          method: "GET",
        };
      },
    }),

    getAllDetailsById: builder.query<
      CommonResponseType & { data: any },
      { page?: number; userId: any; search?: string; limit: number }
    >({
      query: ({ page, userId, search, limit }) => {
        let url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?page=${page}&search=${search}&limit=${limit}`;

        return {
          url,
          method: "GET",
        };
      },
    }),

    addUserApi: builder.mutation<
      CommonResponseType & { data: any },
      { body: CommonBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.user}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GETAGENT"]
    }),

    EditUser: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GETAGENT","GETCOMPANY"]
    }),

    deleteByIduser: builder.mutation<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => ({
        url: `${END_POINTS.user}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GETAGENT","GETCOMPANY"]
    }),
    getChatListing: builder.query<
      CommonResponseType & { data: any },
      { userId: any }
    >({
      query: ({ userId }) => {
        const url = `${END_POINTS.user}/${userId}/${END_POINTS.details}?detailType=4&limit=10`;
        return {
          url,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useLazyGetAllUserQuery,
  useGetPendingCompanyQuery,
  useLazyGetPendingCompanyQuery,
  useLazyGetSpecificUserByIdQuery,
  useAddUserApiMutation,
  useEditUserMutation,
  useLazyGetPendingAgentQuery,
  useLazyGetAllDetailsByIdQuery,
  useLazyGetSpecificCompanyAllAgentsByIdQuery,
  useLazyGetSpecificCompanyPropertyByIdQuery,
  useLazyGetTokenListingQuery,
  useLazyGetChatListingQuery,
  useLazyGetReelByAgentQuery,
  useLazyGetAgentInsightsQuery,
  useLazyGetAgentCriteriaQuery,
  useGetPendingAgentQuery,
  useDeleteByIduserMutation
} = authApi;
