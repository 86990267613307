import { useEffect, useState } from "react";
import { PropertyType } from "../types/General";
import { PostPropertyApi } from "../services/propertyListing";
type Amenities = {
  id: string;
  name: string;
};
const useGetFilters = () => {
  const [getType] = PostPropertyApi.useLazyGetPropertyTypeQuery();
  const [getCategory] = PostPropertyApi.useLazyGetCategoryQuery();
  const [getAmenities] = PostPropertyApi.useLazyGetAmenitiesQuery();
  const [getPropertyCondition] =
    PostPropertyApi.useLazyGetPropertyConditionQuery();

  const [propertyType, setPropertyType] = useState<PropertyType[]>([]);
  const [cat, setCat] = useState<any[]>([]);
  const [amenity, setAmenity] = useState<any[]>([]);
  const [condition, setCondition] = useState<any[]>([]);

  const fetchType = async () => {
    try {
      const res = await getType({}).unwrap();
      if (res?.statusCode === 200) {
        setPropertyType(res?.data);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };

  const fetchCategory = async () => {
    try {
      const res = await getCategory({}).unwrap();
      if (res?.statusCode === 200) {
        setCat(res?.data);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };
  const fetchCondition = async () => {
    try {
      const res = await getPropertyCondition().unwrap();
      if (res?.statusCode === 200) {
        setCondition(res?.data);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };

  const fetchAmenities = async () => {
    try {
      const res = await getAmenities({}).unwrap();
      if (res?.statusCode === 200) {
        let data = res?.data?.map((item, index) => {
          return { id: item?._id, name: item?.name };
        });
        setAmenity([...data]);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
    }
  };

  useEffect(() => {
    fetchAmenities();
    fetchCategory();
    fetchType();
    fetchCondition();
  }, []);

  return { cat, amenity, propertyType, condition };
};

export default useGetFilters;
