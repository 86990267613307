import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


interface CategoryResponse extends CommonResponseType {
    data: any;
}

interface QueryParams {
    userId?: any;
    page?: any
}

export const PropertyStatusApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postAddpropertyStatus: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.propertyCondition,
                method: "POST",
                body,
            }),
            invalidatesTags:["GETPROPERTYSTATUS"]
        }),
        getAllpropertyStatus: builder.query<CommonResponseType & { data: any }, { search?: string }>({
            query: ({ search }) => {
                let url = `${END_POINTS.propertyCondition}?search=${search}`;


                return {
                    url: url,
                    method: "GET",
                };
            },
            keepUnusedDataFor:300,
            providesTags:["GETPROPERTYSTATUS"]
        }),


        getStatusById: builder.query<CategoryResponse, QueryParams>({
            query: ({ userId }) => {
                let url = `${END_POINTS.propertyCondition}/${userId}`;

                return {
                    url,
                    method: 'GET',
                };
            },
        }),



        EditpropertyStatus: builder.mutation<
            CommonResponseType & { data: CommonBody },
            { id: string | undefined; body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.propertyCondition}/${id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags:["GETPROPERTYSTATUS"]
        }),

        

        deleteByIdpropertyStatus: builder.mutation<CommonResponseType & { data: any }, { userId: any }>({
            query: ({ userId }) => ({
                url: `${END_POINTS.propertyCondition}/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags:["GETPROPERTYSTATUS"]
        }),

    }),
});

export const {
    usePostAddpropertyStatusMutation,
    useLazyGetAllpropertyStatusQuery,
    useGetAllpropertyStatusQuery,
    useLazyGetStatusByIdQuery,
    useEditpropertyStatusMutation,
    useDeleteByIdpropertyStatusMutation
} = PropertyStatusApi;
