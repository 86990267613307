import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  NativeSelect,
  Paper,
  Popper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ClearIcon from '@mui/icons-material/Clear';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import {
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  BarChart,
  Legend,
  Rectangle,
  Bar,
  Cell,
  Tooltip,
  CartesianGrid,
} from "recharts";

import { LineChart, PieChart } from "@mui/x-charts";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { DayPicker } from "react-day-picker";
const AgentInsights = ({
  getAgentsInsight,
  agentData,
  superAgent,
}: {
  getAgentsInsight: any;
  agentData: any;
  superAgent?: boolean;
}) => {
  const navigate = useNavigate();
  const { _id: id } = useParams();

  const [open, setOpen] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    getAgentsInsight({ startDate: range?.from, endDate: range?.to });
  }, [range]);
  return (
    <>
      <div className="page_heading main flex">
        <h3>Agent Insights</h3>
        {/* <p>Based on past 60 days</p> */}
        <div className="control_group">
          <TextField
            // label="Select Date Range"
            variant="outlined"
            hiddenLabel
            className="text_field-range w_25"
            fullWidth
            onClick={handleClick}
            value={getFormattedRange()}
            placeholder="Select Date Range"
            InputProps={{
              readOnly: true,
              endAdornment: range?.from ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setRange({
                        from: undefined,
                        to: undefined
                      });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton>
                    <CalendarTodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
            <div
              style={{
                padding: 8,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "8px",
                }}
              >
                <Chip
                  label="Last Week"
                  variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastWeek")}
                />
                <Chip
                  label="Last Month"
                  variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastMonth")}
                />
                <Chip
                  label="Last Year"
                  variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastYear")}
                />
              </div>

              <DayPicker
                className="react-day-picker"
                mode="range"
                selected={range}
                onSelect={handleSelect}
                // numberOfMonths={1}
                month={month}
                onMonthChange={handleMonthChange}
              // styles={{
              //   months: { display: "flex", gap: "16px" },
              //   month: { margin: 0 },
              // }}
              />
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <div className="agnts_mn">
        <div className="dashCard agnt_crds_1">
          <h4>{superAgent ? "Super" : "Total"} agents</h4>
          <h3 className="lrg_fnt">
            {(superAgent
              ? agentData?.agentsCount?.super
              : agentData?.agentsCount?.total) || 0}
          </h3>
          {!superAgent && (
            <p>
              <span
                className={
                  Number(agentData?.totalAgentsPercentage) < 0
                    ? "c_error"
                    : "c_success"
                }
              >
                {Number(agentData?.totalAgentsPercentage) < 0
                  ? `${agentData?.totalAgentsPercentage || 0}%`
                  : `+${agentData?.totalAgentsPercentage || 0}%`}
              </span>
            </p>
          )}
          {!superAgent && (
            <div className="flex_row">
              <div>
                <h4 className="brdr_ln">Super agents</h4>
                <h3>{agentData?.agentsCount?.super || 0}</h3>
              </div>
              <div>
                <h4 className="brdr_ln">Other agents</h4>
                <h3>
                  {agentData?.agentsCount?.total -
                    agentData?.agentsCount?.super -
                    agentData?.agentsCount?.verified || 0}
                </h3>
              </div>
              <div>
                <h4 className="brdr_ln"> Verified agents</h4>
                <h3>{agentData?.agentsCount?.verified || 0}</h3>
              </div>
            </div>
          )}
          {/* <Box component="a">How to become a SuperAgent →</Box> */}
        </div>
        <div className="dashCard agts_crd">
          <h4>Highest number of leads</h4>
          <ol className="agnt_insgts_lst">
            {Array.isArray(agentData?.leads) && agentData?.leads?.length ? (
              agentData?.leads?.map((data: any) => {
                return (
                  <li style={{ cursor: "pointer" }} onClick={() => navigate(`/manage-agents/details/${data?._id}`)}>
                    <LeaderboardIcon />
                    <div className="agnt_insgts">
                      <p>{data?.name || "No name"}</p>
                      <h6>{`${data?.leads + " leads"}`}</h6>
                    </div>
                  </li>
                );
              })
            ) : (
              <h3>No Leads</h3>
            )}

            <Button
              className="btn btn_primary"
              onClick={() =>
                navigate(
                  `/agent-insights?type=2${superAgent ? "&super=true" : ""}${id ? "&companyid=" + id : ""}`
                )
              }
            >
              View more
            </Button>
          </ol>
        </div>
        <div className="dashCard agts_crd">
          <h4>Highest number of Live listing</h4>
          <ol className="agnt_insgts_lst">
            {Array.isArray(agentData?.listing) && agentData?.listing?.length ? (
              agentData?.listing?.map((data: any) => {
                return (
                  <li style={{ cursor: "pointer" }} onClick={() => navigate(`/manage-agents/details/${data?._id}`)}>
                    <LeaderboardIcon />
                    <div className="agnt_insgts">
                      <p>{data?.name || "No name"}</p>
                      <h6>{`${data?.properties + " listings"}`}</h6>
                    </div>
                  </li>
                );
              })
            ) : (
              <h3>No Listings</h3>
            )}
            <Button
              className="btn btn_primary"
              onClick={() =>
                navigate(
                  `/agent-insights?type=1${superAgent ? "&super=true" : ""}${id ? "&companyid=" + id : ""}`
                )
              }
            >
              View more
            </Button>
          </ol>
        </div>
        <div className="dashCard agts_crd">
          <h4>Highest quality score</h4>
          <ol className="agnt_insgts_lst">
            {Array.isArray(agentData?.score) && agentData?.score?.length ? (
              agentData?.score?.map((data: any) => {
                return (
                  <li style={{ cursor: "pointer" }} onClick={() => navigate(`/manage-agents/details/${data?._id}`)}>
                    <LeaderboardIcon />
                    <div className="agnt_insgts">
                      <p>{data?.name || "No name"}</p>
                      <h6>{data?.percent ? `${data?.percent}%` : `0%`}</h6>
                    </div>
                  </li>
                );
              })
            ) : (
              <h3>No quality score</h3>
            )}
            <Button
              className="btn btn_primary"
              onClick={() =>
                navigate(
                  `/agent-insights?type=3${superAgent ? "&super=true" : ""}${id ? "&companyid=" + id : ""}`
                )
              }
            >
              View more
            </Button>
          </ol>
        </div>
      </div>
    </>
  );
};

export default AgentInsights;
