import { END_POINTS } from "../helpers";
import { PropertyType } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

interface PropertyBody {
  propertyTypeId: string;
  name: string;
  categoryId: string;
  price: number;
  address: string;
  keyFeature: string;
  specifications: string;
  area: string;
  images: string[];
  latitude: number;
  longitude: number;
  propertyConditionId: string;
  amenitiesId: string[];
  beds: string;
  baths: string;
  advertisementPermitNo: string;
  agentId?: string;
}
export const PostPropertyApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<CommonResponseType & { data: any[] }, {}>({
      query: ({}) => ({
        url: `${END_POINTS.category}?type=subcategory`,
        method: "GET",
      }),
    }),

    getAmenities: builder.query<CommonResponseType & { data: any[] }, {}>({
      query: ({}) => ({
        url: `${END_POINTS.amenities}`,
        method: "GET",
      }),
    }),
    getPropertyType: builder.query<
      CommonResponseType & { data: PropertyType[] },
      {}
    >({
      query: ({}) => ({
        url: `${END_POINTS.propertyType}`,
        method: "GET",
      }),
    }),
    getPropertyCondition: builder.query<
      CommonResponseType & { data: any[] },
      void
    >({
      query: () => ({
        url: `${END_POINTS.propertyCondition}`,
        method: "GET",
      }),
    }),
    postProperty: builder.mutation<
      CommonResponseType & { data: any },
      { body: PropertyBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.property}`,
        method: "POST",
        body,
      }),
    }),

    getProperty: builder.query<
      CommonResponseType & { data: any },
      { page: number; limit: number }
    >({
      query: ({ page, limit }) => ({
        url: `${END_POINTS.property}?page=${page + 1}&limit=${limit}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetCategoryQuery,
  usePostPropertyMutation,
  useLazyGetPropertyQuery,
  useLazyGetAmenitiesQuery,
  useLazyGetPropertyConditionQuery,
} = PostPropertyApi;
