import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetAllDetailsByIdQuery, useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { UserData } from "../../types/General";
import { ImageModal } from "../../components";
import Loader from "../../helpers/constants/Loader";

const Details = () => {
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<UserData>();
  const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const handleClose = () => {
    setOpen(false)
  }

  const fetchDataById = async () => {
    try {
      const response = await fetchApi({ userId: _id, detailsType: 1 }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data || {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  useEffect(() => {
    if (_id) { fetchDataById(); }
  }, [])



  return (
    <Card className="cards">
      <Loader isLoad={isLoading} />
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src={ByIdDetails?.image || `/static/images/user_placeholder.png`} alt="" />
              <div className="overlay">
                <img
                  onClick={() => handleOpenModal(ByIdDetails?.image ? ByIdDetails?.image : "/static/images/user_placeholder.png")}
                  src='/static/images/visibility.png' alt='img'
                />
              </div>
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Name</Typography>
                  <Typography component="p">{ByIdDetails?.name || "-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">
                    {ByIdDetails?.dialCode && ByIdDetails?.phoneNo
                      ? `${ByIdDetails.dialCode}${ByIdDetails.phoneNo}`
                      : ''}
                  </Typography>

                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p"> {ByIdDetails?.email}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Address</Typography>
                  <Typography component="p">{ByIdDetails?.address || "-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Emirates Id</Typography>
                  <Typography component="p">{ByIdDetails?.emiratesId || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p">{ByIdDetails?.isBlocked === false ? "Active" : "Blocked"}</Typography>
                </Box>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <ImageModal open={open} handleClose={handleClose} image={selectedImage} />
    </Card>
  );
};

export default Details;
