import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


interface CategoryResponse extends CommonResponseType {
  data: any;
}

interface QueryParams {
  userId?: any;
}

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddboostProfile: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.boostProfile,
        method: "POST",
        body,
      }),
    }),
    getAllBoostProfile: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; limit: number }>({
      query: ({ page, search, limit }) => {
        let url = `${END_POINTS.boostProfile}?page=${page}&search=${search}&limit=${limit}`;


        return {
          url: url,
          method: "GET",
        };
      },
    }),


    getSpecificBoostProfileById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId }) => ({
        url: `${END_POINTS.boostProfile}/${userId}`,
        method: 'GET',
      }),
    }),


    EditBoostProfile: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.boostProfile}/${id}`,
        method: "PUT",
        body,
      }),
    }),



    deleteByIdBoostProfile: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.boostProfile}/${userId}`,
        method: "DELETE",
      }),
    }),

  }),
});

export const {
  usePostAddboostProfileMutation,
  useLazyGetAllBoostProfileQuery,
  useLazyGetSpecificBoostProfileByIdQuery,
  useEditBoostProfileMutation,
  useLazyDeleteByIdBoostProfileQuery,

} = authApi;
