import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";

export const LineChartComponent = ({ data, num, height }: { data: any, num: string, height: number }) => {

  const dummyData = [{
    name: "",
    count: 0
  }]

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart data={data?.length ? data : dummyData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey={data?.length ? num : "count"} stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer >
  );
};
