import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  Select,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  usePostAddCategoryMutation,
  useLazyGetSpecificCategoryByIdQuery,
  useEditCategoryMutation,
  useLazyGetAllCategoryQuery,
} from "../../services/category";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast, warnToast } from "../../helpers";
import { UploadMedia } from "../../utils/uploadMedia";
import Loader from "../../helpers/constants/Loader";
import { isNumber } from "../../utils/validation";

const AddCategory = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState("");
  const [fetchApi, { data }] = useLazyGetSpecificCategoryByIdQuery();
  const [fetchAddCategory, { isLoading }] = usePostAddCategoryMutation();
  const [updateCategory] = useEditCategoryMutation();
  const [categories, setCategories] = useState<any>([])
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();
  const [category, setCategory] = useState<string>("")



  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategory({
        limit: 50,
        page: 1,
        search: "",
        type: "category",
        parentId: ""
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };


  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        setProfilePicture("");
        // setImageError("Image is required");
        return;
      }

      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        const imageUrl = res?.data[0]?.location;
        setProfilePicture(imageUrl);
        successToast("Image uploaded successfully.");
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const fetchDataById = async (id: any) => {
    try {
      const response = await fetchApi({ userId: id }).unwrap();
      if (response?.statusCode === 200) {
        setProfilePicture(response?.data?.image || "");
        setCategory(response?.data?.parentId?._id || "")
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      // token: ""
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(40, "Maximum 40 characters are allowed")
        .min(3, "Minimum 3 characters are required"),

      // token: Yup.string()
      //   .required("This field is required")
      //   .max(10, "Maximum 10 characters are allowed")
    }),
    onSubmit: async (values) => {
      if (!profilePicture) {
        warnToast("Kindly upload an image");
        return;
      }
      if (!category) {
        warnToast("Please select a category");
        return;
      }


      const body = {
        name: values.title,
        image: profilePicture,
        // token: Number(values.token),
        parentId: category
      };
      if (!_id)
        try {
          const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await fetchAddCategory(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            successToast(response.data.message || "Sub-category Added Successfully");
            navigate("/manage-categories/");
          } else {
            console.log("errrrrrrrr", response)
            errorToast("error")
          }
        } catch (e: any) {
          console.log("error0000", e)
          errorToast(e?.data?.message);
        }

      else {
        try {
          const encryptedBody2 = generateEncryptedKeyBody(body) as CommonBody;
          console.log('Encrypted body:', encryptedBody2); // Log the encrypted body
          const response = await updateCategory({
            id: _id,
            body: encryptedBody2,
          }).unwrap();
          console.log('Response:', response);
          if (response.statusCode === 200) {
            successToast("Sub-category Updated Successfully");
            navigate("/manage-categories/");
          } else {
            console.log(response, "00000000")
            errorToast(response?.message)
          }
        } catch (e: any) {
          console.log("Error1:", e);
          errorToast(e?.message);
        }


      }

    },
  });



  useEffect(() => {
    if (data && data.statusCode === 200) {
      formik.setValues({
        title: data?.data?.name || "",
        // token: data?.data?.settings?.token || ""
      });
    }
  }, [data]);

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <div>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{_id ? "Edit Sub-category" : "Add Sub-category"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {profilePicture ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          style={{ borderRadius: 0 }}
                          image={profilePicture}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={(e) => {
                            e.preventDefault();
                            setProfilePicture("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button
                            component="span"
                            className="upload_image_btn"
                          >
                            <img
                              style={{ borderRadius: 0 }}
                              src="/static/images/product1.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>

                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Sub-category Name
                    </Typography>
                    <TextField
                      hiddenLabel

                      type={"text"}
                      name="title"
                      inputProps={{ maxLength: 40 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.title}
                      placeholder="Sub-category Name"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.title && formik.errors.title
                          ? formik.errors.title.toString()
                          : ""
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Typography className="custom_label">
                      Number of tokens
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="token"
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      value={formik.values.token}
                      placeholder="Enter tokens"
                      className="text_field"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (isNumber(e.target.value)) {
                          formik.handleChange(e)
                        } else {
                          return;
                        }
                      }}
                      helperText={
                        formik.touched.token && formik.errors.token
                          ? formik.errors.token.toString()
                          : ""
                      }
                    />
                  </Grid> */}
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Categories
                    </Typography>
                    <Select
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {categories && categories.length > 0
                        ? categories.map((it: any) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
