
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { generateResponsiveStyle } from "../utils/modalStyle";
import CloseIcon from "@mui/icons-material/Close";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
};

const ReasonModal = ({
  open,
  setOpen,
  handleDelete,
  reason,
  setReason,
}: props) => {
  const style = generateResponsiveStyle();
  const isReasonEmpty = reason.trim() === "";

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal_title">
          <Typography id="modal-modal-title" component="h2">Write a reason why you are rejecting this request.</Typography>
          <CloseIcon className="cross_icn_logout" onClick={() => setOpen(false)} />
        </div>

        <div className="modal_body">
          <div className="control_group">
            <TextField
              className="text_field"
              hiddenLabel
              type={"text"}
              name="cancelReason"
              variant="outlined"
              fullWidth
              id="name"
              placeholder="Enter a reason"
              value={reason}
              onChange={(val) => setReason(val.target.value)}
            />
          </div>
          <div className="form_btn">
            <Button
              className="btn btn_primary"
              disabled={isReasonEmpty}
              onClick={() => {
                setOpen(false);
                setReason("");
                handleDelete();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ReasonModal;
