import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'getprop-8ab7b.firebaseapp.com',
  projectId: 'getprop-8ab7b',
  storageBucket: 'getprop-8ab7b.appspot.com',
  messagingSenderId: '75089714884',
  appId: '1:75089714884:web:0dd00e6d2bd00fa36e7391',
  measurementId: 'G-XGHNE9NNYF',
};

const app = initializeApp(firebaseConfig);
export default app;
