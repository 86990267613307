import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
 

const AddCompanySubscription = () => {
  const navigate = useNavigate();

  const [category, setCategory] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("image")) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts images.");
    }
  };

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0].type.includes("video")) {
      setFileName(files[0].name);
      setVideo(URL.createObjectURL(files[0]));
    } else {
      setAlertType(0);
      setShowAlert(true);
      setAlertMessage("This field only accepts videos.");
    }
  };

  const handleMediaCancel = () => {
    setImage("");
    setVideo("");
    setFileName("");
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
    <div>
      {/* <div className="main_layout">
        <div className="dashboard">
          <h3>Add Exercise</h3>
          <Button
              className="btn btn_primary"
            onClick={() => {
              navigate("/manage-exercises");
            }}
          >
            Back
          </Button>
        </div> */}
         <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Company Subscription</h1>
          <Button
            className="btn btn_primary"
            onClick={() =>               navigate("/manage-company-subscription")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                 
                  <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{ accept: "image/png,image/jpeg" }}
                          // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          //   handleImageUpload(e)
                          // }
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                          style={{borderRadius:0}}
                            src="/static/images/product1.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                     
                </Grid>
                
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Subscription Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="subscription"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Subscription Name"
                  />
                </Grid>
                
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                   Validity
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Validity"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                  Maximum no of agents can be added
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="agents"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Maximum no of agents can be added"
                  />
                </Grid>
              
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Subscription Type
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        onChange={handleCategoryChange}
                        displayEmpty
                        // sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Basic">Monthly Special </MenuItem>
                        <MenuItem value="Standard">Yearly Premium</MenuItem>
                       
                         
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                    {/* Add the description text field below the video upload */}
                    {/* <Grid item lg={4} md={4} sm={6} xs={12}></Grid> */}
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Description
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Description"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Featurs
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="Featurs"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Featurs"
                  />
                </Grid>
             
               
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default AddCompanySubscription;
