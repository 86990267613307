import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Chip, ClickAwayListener, FormControl, Grid, IconButton, InputAdornment, NativeSelect, Paper, Popper, TextField } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import LineChart from "../../components/LineChart";
import { useNavigate } from "react-router-dom";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FiberNewIcon from "@mui/icons-material/FiberNew";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PaidIcon from '@mui/icons-material/Paid';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from '@mui/icons-material/Clear';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AddCommentIcon from "@mui/icons-material/AddComment";
import {
  useLazyGetAgentGraphQuery,
  useLazyGetCompanyGraphQuery,
  useLazyGetDashboArdApiQuery,
  useLazyGetPropertyGraphQuery,
  useLazyGetRevenueGraphQuery,
} from "../../services/dashboard";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { DashboardData } from "../../types/General";
import { useLazyGetUserGraphQuery } from "../../services/dashboard";
import Loader from "../../helpers/constants/Loader";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const [data, setData] = useState<DashboardData>();
  const [UserData] = useLazyGetUserGraphQuery();
  const [RevenueData] = useLazyGetRevenueGraphQuery();
  const [user, serUser] = useState<any>([]);
  const [revenue, setRevenue] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [company, setCompany] = useState<any>([]);
  const [AgentData] = useLazyGetAgentGraphQuery();
  const [property, setProperty] = useState<any>([]);
  const [CompanyData] = useLazyGetCompanyGraphQuery();
  const [PropertyData] = useLazyGetPropertyGraphQuery();
  const [fetchDashBoard, { isLoading }] = useLazyGetDashboArdApiQuery();
  const [selectedType1, setSelectedType1] = useState<string>("weekly");
  const [selectedType2, setSelectedType2] = useState<string>("weekly");
  const [selectedType4, setSelectedType4] = useState<string>("weekly");
  const [selectedType3, setSelectedType3] = useState<string>("weekly");
  const [selectedType5, setSelectedType5] = useState<string>("weekly");


  const [open, setOpen] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const RankBox = ({ icon, rank, action }: any) => (
    <div className="rank_box">
      {icon}
      <p>Your rank is {rank}</p>
      {/* <Box component="a">{action}</Box> */}
    </div>
  );

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };


  const fetchUsersGraph = async (type: string) => {
    try {
      const response = await UserData(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData =
        response?.data?.totalUsersGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      serUser(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchRevenueGraph = async (type: string) => {
    try {
      const response = await RevenueData(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData =
        response?.data?.totalRevenueGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: (item.count),
        })) || [];
     

      setRevenue(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    fetchRevenueGraph(selectedType2);
  }, [selectedType2]);

  useEffect(() => {
    fetchUsersGraph(selectedType1);
  }, [selectedType1]);


  function formatRevenue(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }



  const graphUsersData = {
    labels: user.length !== 0 ? user?.map((item: any) => item.label) : [],
    datasets: [
      {
        label: "Total Users",
        data: user.length !== 0 ? user?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };

  const graphRevenueData = {
    labels: revenue?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Revenue",
        data:
          revenue.length !== 0 ? revenue?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };

  const graphPropertyData = {
    labels: property?.map((item: any) => item.label),
    datasets: [
      {
        label: "Total Properties",
        data:
          property.length !== 0 ? property?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };
  const graphAgentData = {
    labels: agent.length !== 0 ? agent?.map((item: any) => item.label) : [],
    datasets: [
      {
        label: "Total Agents",
        data: agent.length !== 0 ? agent?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };
  const graphCompanyyData = {
    labels: company.length !== 0 ? company?.map((item: any) => item.label) : [],
    datasets: [
      {
        label: "Total Companies",
        data:
          company.length !== 0 ? company?.map((item: any) => item.value) : [],
        borderColor: "black",
        backgroundColor: "#AAAAAA",
      },
    ],
  };
  const fetchAgentGraph = async (type: string) => {
    try {
      const response = await AgentData(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData =
        response?.data?.totalUsersGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setAgent(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchPropertyGraph = async (type: string) => {
    try {
      const response = await PropertyData(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData =
        response?.data?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setProperty(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchCompanyGraph = async (type: string) => {
    try {
      const response = await CompanyData(type).unwrap();
      console.log("res---  analysis", response);
      const formattedData =
        response?.data?.totalUsersGraph?.userData?.map((item: any) => ({
          label: item.name,
          value: item.count,
        })) || [];

      setCompany(formattedData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType1(newType);
  };
  const handleChange2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType2(newType);
  };
  const handleChange3 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType3(newType);
  };
  const handleChange4 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType4(newType);
  };
  const handleChange5 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setSelectedType5(newType);
  };
  const fetchApi = async () => {
    try {
      const response = await fetchDashBoard({
        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      setData(response?.data);
    } catch (e) { }
  };
  useEffect(() => {
    fetchApi();
  }, [range]);
  console.log(range, "range");

  useEffect(() => {
    fetchPropertyGraph(selectedType3);
  }, [selectedType3]);
  useEffect(() => {
    fetchAgentGraph(selectedType4);
  }, [selectedType4]);
  useEffect(() => {
    fetchCompanyGraph(selectedType5);
  }, [selectedType5]);
  const navigate = useNavigate();

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
        <div className="filters">
          <div className="control_group ">
            <TextField
              variant="outlined"
              hiddenLabel
              className="text_field-range w_25"
              fullWidth
              onClick={handleClick}
              value={getFormattedRange()}
              placeholder="Select Date Range"
              InputProps={{
                readOnly: true,
                endAdornment: range?.from ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setRange({
                          from: undefined,
                          to: undefined
                        });
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </div>
        </div >
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
            <div
              style={{
                padding: 16,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Chip
                  label="Last Week"
                  variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastWeek")}
                />
                <Chip
                  label="Last Month"
                  variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastMonth")}
                />
                <Chip
                  label="Last Year"
                  variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                  onClick={() => handleChipClick("lastYear")}
                />
              </div>

              <DayPicker
                className="react-day-picker"
                mode="range"
                selected={range}
                onSelect={handleSelect}
                // numberOfMonths={1}
                month={month}
                onMonthChange={handleMonthChange}
              // styles={{
              //   months: { display: "flex", gap: "16px" },
              //   month: { margin: 0 },
              // }}
              />
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Loader isLoading={isLoading} />

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-users")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4 className="mn_hdng">{data?.totalUsers}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-agents")}
          >
            <SupportAgentIcon className="svg_icn" />
            <div>
              <h3>Total Agents</h3>
              <h4 className="mn_hdng">{data?.totalAgents}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-company")}
          >
            <ApartmentIcon className="svg_icn" />
            <div>
              <h3>Total Companies</h3>
              <h4 className="mn_hdng">{data?.totalCompanies}</h4>
            </div>
          </Item>
        </Grid>
        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-ads")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Ads</h3>
              <h4 className="mn_hdng">{data?.totalAds}</h4>
            </div>
          </Item>
        </Grid> */}
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/property-listing")}
          >
            <SubscriptionsIcon className="svg_icn" />
            <div>
              <h3>Total Properties</h3>
              <h4 className="mn_hdng">{data?.properties}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-ads")}
          >
            <AddCommentIcon className="svg_icn" />
            <div>
              <h3>Total Ads</h3>
              <h4 className="mn_hdng">{data?.totalAds}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-users")}
          >
            <FiberNewIcon className="svg_icn" />
            <div>
              <h3>New Users</h3>
              <h4 className="mn_hdng">{data?.newUsers}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manage-subscription")}
          >
            <PriceChangeIcon className="svg_icn" />
            <div>
              <h3>Total additional top-up credits</h3>
              <h4 className="mn_hdng">{data?.additionalTopUpCredits ? "AED "+formatRevenue(data?.additionalTopUpCredits) : 0}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/listing-performance/dashboard")}
          >
            <LeaderboardIcon className="svg_icn" />
            <div>
              <h3>Total Leads</h3>
              <h4 className="mn_hdng">{data?.totalLeads ? formatRevenue(data?.totalLeads) : 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-property-requests")}
          >
            <PaidIcon className="svg_icn" />
            <div>
              <h3>Total number of Claimed transactions</h3>
              <h4 className="mn_hdng">{data?.totalClaimedTransactions || 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-property-requests")}
          >
            <ReceiptIcon className="svg_icn" />
            <div>
              <h3>Total value of Claimed transactions</h3>
              <h4 className="mn_hdng">{data?.totalValueOfClaimedTransactions ?  "AED "+ formatRevenue(data?.totalValueOfClaimedTransactions) : 0}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-subscription")}
          >
            <SubscriptionsIcon className="svg_icn" />
            <div>
              <h3>Total Subscriptions</h3>
              <h4 className="mn_hdng">{data?.subscriptions}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-subscription")}
          >
            <LoyaltyIcon className="svg_icn" />
            <div>
              <h3>Total Value of Subscriptions</h3>
              <h4 className="mn_hdng">{data?.totalValueOfSubscription ?  "AED "+formatRevenue(data?.totalValueOfSubscription) : 0}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/listing-action-tracker")}
          >
            <TroubleshootIcon className="svg_icn" />
            <div>
              <h3>Total Listing action trackers</h3>
              <h4 className="mn_hdng">{data?.totalListingActionTrackers || 0}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Users
              <FormControl>
                <div>
                  {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                  <NativeSelect
                    defaultValue={selectedType1}
                    onChange={handleChange1}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphUsersData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Agents
              <FormControl>
                <div>
                  <NativeSelect
                    defaultValue={selectedType4}
                    onChange={handleChange4}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphAgentData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Companies
              <FormControl>
                <div>
                  {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                  <NativeSelect
                    defaultValue={selectedType5}
                    onChange={handleChange5}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphCompanyyData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Revenue
              <FormControl>
                <div>
                  {/* <Button sx={{margin:"5px"}}className="btn btn_primary" onClick={handleExportUserCsv}>
                <FileDownloadIcon /> Export CSV
              </Button> */}
                  <NativeSelect
                    defaultValue={selectedType2}
                    onChange={handleChange2}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Proprties
              <FormControl>
                <div>
                  <NativeSelect
                    defaultValue={selectedType3}
                    onChange={handleChange3}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </NativeSelect>
                </div>
              </FormControl>
            </h2>
            <LineChart data={graphPropertyData} />
          </Item>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Downloads</h2>
            <LineChart data={graphDownloadsData} />
          </Item>
        </Grid> */}
      </Grid>
    </div >
  );
};

export default DashBoard;
