import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tabs,
  Tab,

} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetChatListingQuery, useLazyGetSpecificUserByIdQuery } from "../../services/users";
import WarnModal from "../../components/WarnModal";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/constants/Loader";
import { UserData } from "../../types/General";
import moment from "moment";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ContactedAgents = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [TokenListing, { isLoading }] = useLazyGetChatListingQuery();
  const [fetchEmail] = useLazyGetSpecificUserByIdQuery();
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / limit);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  const fetchApi = async (userId: any) => {
    try {
      const response = await TokenListing({ userId: _id }).unwrap();
      setData(response?.data?.chats || []);
      setTotalCount(response?.data?.count);
    } catch (e: any) {

      errorToast(e?.data?.message || "");
    }
  };

  const fetchDataById = async () => {
    try {
      const response = await fetchEmail({ userId: _id, detailsType: 13 }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data?.emailMessages || []);
        setTotalCount(response?.data?.count)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id && value === 0) {
      fetchApi(_id);
    } else if (_id && value === 1) {
      fetchDataById()
    }
  }, [value]);
  return (
    <Card className="cards">

      <Box className="custom_tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
          className="custom_tabs_links"
        >
          <Tab label="In-App Chat" {...a11yProps(0)} />
          <Tab label="Email" {...a11yProps(1)} />
          <Tab label="WhatsApp" {...a11yProps(2)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No chat found
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((row, index) => (
                    <TableRow key={row.connectionId}>
                      <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                      <TableCell>{row?.senders?.name || "-"}</TableCell>
                      <TableCell>{row?.receivers?.name || "-"}</TableCell>
                      <TableCell>{moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a') || "-"}</TableCell>
                      <TableCell>{row?.properties?.name || "-"}</TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate
                                (`/user-chat-details/${_id}/${row?.connectionId}`)
                            }>
                            <VisibilityIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>

          </TableContainer>
          {data?.length > 0 ? (
            <Pagination
              setPage={setPage}
              module={data}
              page={page}
              limit={limit}
              setLimit={setLimit}
              onPageChange={onPageChange}
              totalPages={totalPages}
            />
          ) : (
            ""
          )}

        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Agent Name</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ByIdDetails?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Email found
                    </TableCell>
                  </TableRow>
                ) : (
                  ByIdDetails?.map((row: any, index: number) => (
                    <TableRow key={row._id}>
                      <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                      <TableCell>{row?.agent?.name || "-"}</TableCell>
                      <TableCell>{row?.agent?.companyName || "-"}</TableCell>
                      <TableCell>{moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a') || "-"}</TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate
                                (`/user-email-details/${_id}`, { state: row })
                            }>
                            <VisibilityIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>

          </TableContainer>
          {ByIdDetails?.length > 0 ? (
            <Pagination
              setPage={setPage}
              module={ByIdDetails}
              page={page}
              limit={limit}
              setLimit={setLimit}
              onPageChange={onPageChange}
              totalPages={totalPages}
            />
          ) : (
            ""
          )}

        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>


        </CustomTabPanel>
      </Box>

    </Card>
  );
};

export default ContactedAgents;
