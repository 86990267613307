import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Box,
  Button,
  Card,
  Chip,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";

import Loader from "../../helpers/constants/Loader";

import { isValidInput } from "../../utils/validation";
import SearchBar2 from "../../components/SearchBar2";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { Clear } from "@mui/icons-material";
import { useLazyGetAllReportsQuery } from "../../services/propertyReports";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


const ManagePropertyReports = () => {
  const navigate = useNavigate();
  const [rows1, setRows] = useState<any>([]);
  const [page, setPage] = useState(1);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [open2, setOpen2] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });


  const handleClose = () => {
    setOpen2(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen2(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const [fetchReports, { isLoading }] = useLazyGetAllReportsQuery();
  const fetchAllAddsApi = async () => {
    console.log(limit, "limit in fun");
    try {
      const response = await fetchReports({
        page: page, limit: limit, search: debouncedSearchTerm,
        startDate: range?.from
          ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: range?.to
          ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      setRows(response?.data?.reports || []);
      setTotalCount(response?.data?.count || 0);
    }
    catch (e: any) {
      errorToast(e?.message);
    }
  }



  const [type, setType] = useState("");

  useEffect(() => {
    fetchAllAddsApi()
  }, [page, limit, range, type, debouncedSearchTerm])
  console.log('type :', type);
  return (
    <div>
      <Loader isLoad={isLoading} />


      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Property Reports</h1>
        </div>

        <Card className="cards">
          <Box className="cards_header">
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className="control_group" >
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range"
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end" style={{ marginLeft: -50 }}>
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Popper
                  open={open2}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  disablePortal
                  style={{
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                      <div
                        style={{
                          padding: 16,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Chip
                            label="Last Week"
                            variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastWeek")}
                          />
                          <Chip
                            label="Last Month"
                            variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastMonth")}
                          />
                          <Chip
                            label="Last Year"
                            variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastYear")}
                          />
                        </div>

                        <DayPicker
                          className="react-day-picker"
                          mode="range"
                          selected={range}
                          onSelect={handleSelect}
                          // numberOfMonths={10}
                          month={month}
                          onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                        />
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </Box>
          </Box>



          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>

                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Property Name</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Report Message</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No result found
                    </TableCell>
                  </TableRow>
                ) : (
                  rows1?.map((row1: any, i: number) => (
                    <TableRow key={row1?._id || i}>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell>
                        <figure onClick={() => navigate(`/manage-property-reports/details/${row1?._id}`)} className="user_img">
                          <img src={row1?.images?.[0] || "/static/images/user_placeholder.png"} alt="" />
                        </figure>
                      </TableCell>
                      <TableCell>{row1?.properties?.name || "-"}</TableCell>
                      <TableCell>{row1?.users?.name || "-"}</TableCell>
                      <TableCell>
                        {row1?.issue
                          ? row1.issue.split(" ").slice(0, 3).join(" ") + (row1.issue.split(" ").length > 3 ? "..." : "")
                          : "-"}
                      </TableCell>

                      <TableCell>
                        <Box className="table_actions">
                          <IconButton onClick={() => {
                            navigate(`/manage-property-reports/details/${row1?._id}`)
                          }}>
                            <VisibilityIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows1?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows1}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          limit={limit}
          setLimit={setLimit}
        />
      ) : (
        ""
      )}


    </div>
  );
};

export default ManagePropertyReports;
