import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParmas = {
  id?: string;
  categoryId?: string;
  typeId?: string;
  statusId?: string;
  startDate?: any;
  endDate?: string;
  graphType?: number;
  lat?: number;
  lng?: number;
  creditsType?: string;
  propertyConditionId?: string;
  publishGraph?: string;
  filterType?: string;
  leadType?: string;
};
export const insightsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getListingPerformances: builder.query<
      CommonResponseType & { data: any },
      QueryParmas
    >({
      query: ({
        graphType,
        creditsType,
        startDate,
        endDate,
        lat,
        lng,
        typeId,
        categoryId,
        propertyConditionId,
        publishGraph,
        id,
      }) => {
        // Create an instance of URLSearchParams
        const params = new URLSearchParams();
        const user = id ? `/user/${id}/` : "";
        console.log("id insight :", id);
        // Add parameters conditionally
        if (publishGraph)
          params.append("publishedListingsType", publishGraph.toString());
        if (graphType) params.append("graphType", graphType.toString());
        if (lat) params.append("lat", lat.toString());
        if (lng) params.append("lng", lng.toString());
        if (propertyConditionId)
          params.append("propertyConditionId", propertyConditionId.toString());
        if (creditsType) params.append("creditsType", creditsType);
        if (startDate) params.append("startDate", startDate);
        if (categoryId) params.append("categoryId", categoryId);
        if (typeId) params.append("propertyTypeId", typeId);
        if (endDate) params.append("endDate", endDate);

        return {
          url: `${user}${END_POINTS.leadsInsightPerformance}?${params.toString()}`,
          method: "GET",
        };
      },
    }),

    superAgentInsightsOverview: builder.query<
      CommonResponseType & { data: any },
      { categoryId: string; latitude: number; longitude: number }
    >({
      query: ({ categoryId, latitude, longitude }) => {
        return {
          url: `${END_POINTS.performanceInsightsOverview}?categoryId=${categoryId}&latitude=${latitude}&longitude=${longitude}`,
          method: "GET",
        };
      },
    }),

    // getLeadsInsights: builder.query<
    //   CommonResponseType & { data: any },
    //   QueryParmas
    // >({
    //   query: ({ filterType, leadType }) => {
    //     // Create an instance of URLSearchParams
    //     const params = new URLSearchParams();

    //     if (filterType) params.append("filterType", filterType.toString());
    //     if (leadType) params.append("leadType", leadType.toString());

    //     return {
    //       url: `${END_POINTS.leadsInsights}?${params.toString()}`,
    //       method: "GET",
    //     };
    //   },
    // }),
    // companyPerformance: builder.query<CommonResponseType & { data: any }, {}>({
    //   query: () => {
    //     return {
    //       url: `${END_POINTS.companyPerformance}`,
    //       method: "GET",
    //     };
    //   },
    // }),
    // agentInsights: builder.query<
    //   CommonResponseType & { data: any },
    //   { agentInsightType: number }
    // >({
    //   query: ({ agentInsightType }) => {
    //     return {
    //       url: `${END_POINTS.agentInsights}?agentInsightType=${agentInsightType}`,
    //       method: "GET",
    //     };
    //   },
    // }),
    // addLeads: builder.mutation<
    //   CommonResponseType & { data: any },
    //   { body: any; id: string }
    // >({
    //   query: ({ body, id }) => ({
    //     url: `${END_POINTS.leads}/${id}`,
    //     method: "POST",
    //     body,
    //   }),
    // }),
    // connectWithEmail: builder.mutation<
    //   CommonResponseType & { data: any },
    //   { body: any }
    // >({
    //   query: ({ body }) => ({
    //     url: `${END_POINTS.connectWithEmail}`,
    //     method: "POST",
    //     body,
    //   }),
    // }),
    // feedBackForm: builder.mutation<
    //   CommonResponseType & { data: any },
    //   { body: any }
    // >({
    //   query: ({ body }) => ({
    //     url: `${END_POINTS.feedbackForm}`,
    //     method: "POST",
    //     body,
    //   }),
    // }),
  }),
});

export const {
  useLazyGetListingPerformancesQuery,
  useLazySuperAgentInsightsOverviewQuery,
} = insightsApi;
