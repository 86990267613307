import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const feedbackApis = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postFeedbackApi: builder.mutation<
            CommonResponseType & { data: any },
            { body: CommonBody, id: string }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.feedback}/${id}/${END_POINTS.reply}`,
                method: "PUT",
                body,
            }),
            invalidatesTags:["GETFEEDBACKS"]
        }),
        getAllFeedbacks: builder.query<CommonResponseType & { data: any }, 
        { page?: number; 
            search?:string;
            limit?: number ;
            range?:{ from: string | undefined;
            to: string | undefined;};
            role?:string|number
        }>({
            query: ({ page, limit ,range,role,search}) => {
                let url = `${END_POINTS.feedback}?page=${page}&limit=${limit}&search=${search}&startDate=${range?.from}&endDate=${range?.to}&role=${role}`;
                return {
                    url: url,
                    method: "GET",
                };
            },
            keepUnusedDataFor:300,
            providesTags:["GETFEEDBACKS"]
        }),




    }),
});

export const {
    usePostFeedbackApiMutation,
    useGetAllFeedbacksQuery

} = feedbackApis;
