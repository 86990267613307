import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";
import {
  useLazyGetSpecificSubscriptionByIdQuery,
} from "../../services/subscription";
import Loader from "../../helpers/constants/Loader";
import { DocumentViewer } from "../../components";



const SubscriptionDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false)
  const [isLoading2, setIsLoading2] = useState<boolean>(false)

  const [ByIdDetails, setByIdDetails] = useState<any>([]);

  const [fetchApi, { isLoading }] = useLazyGetSpecificSubscriptionByIdQuery();

  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataById(_id);
  }, [_id])

  useEffect(
    () => {
      if (open)
        setIsLoading2(true);
    }, [open]
  )

  return (
    <div>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Subscription Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  {ByIdDetails?.contract ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        <Typography component="h5">Contract documents</Typography>
                        {
                          ByIdDetails?.contract.endsWith(".pdf") ? (

                            <div className="upload_image_preview2">
                              <CardMedia
                                component="img"
                                image="/static/images/pdf.png"
                                alt="photo"
                                sx={{ width: 200, height: 200, objectFit: "contain" }}
                                onClick={() => setOpen(true)}
                              />

                            </div>
                          ) : (
                            <div className="upload_image_preview2">
                              <CardMedia
                                component="img"
                                image={ByIdDetails?.contract}
                                alt="photo"
                                sx={{ width: 200, height: 200 }}
                              />

                            </div>
                          )}
                      </Box>
                    </Grid>
                  ) : ("")}

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Subscription Name</Typography>
                      <Typography component="p">{ByIdDetails.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Subscription Pack</Typography>
                      <Typography component="p">
                        {ByIdDetails.subscriptionPack === 1 ? "Monthly" :
                          ByIdDetails.subscriptionPack === 3 ? "Quarterly" :
                            ByIdDetails.subscriptionPack === 6 ? "Half-Yearly" :
                              "Yearly"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Price</Typography>
                      <Typography component="p">${ByIdDetails.price || "-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Company</Typography>
                      <Typography component="p">{ByIdDetails?.userId?.name || "-"}</Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Number of Agents</Typography>
                          <Typography component="p">{ByIdDetails.noOfAgents||"-"}</Typography>
                        </Box>
                      </Grid>
                      
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Features</Typography>
                          <Typography component="p">{ByIdDetails.features||"-"}</Typography>
                        </Box>
                      </Grid> */}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">{ByIdDetails.description || "-"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DocumentViewer open={open} setOpen={setOpen} link={ByIdDetails?.contract} isLoading={isLoading2} setIsLoading={setIsLoading2} />
      </div>

    </div>
  );

};

export default SubscriptionDetails;
