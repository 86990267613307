import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};


export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddAgent: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    getAllAgent: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string, role: number; limit: number }>({
      query: ({ role, page, search, limit }) => {
        let url = `${END_POINTS.user}?role=${role}&page=${page}&search=${search}&limit=${limit}`;


        return {
          url: url,
          method: "GET",
        };
      },
    }),



    getSpecificAgentById: builder.query<CommonResponseType & { data: any }, { userId: any }>
      ({
        query: ({ userId }) => ({
          url: `${END_POINTS.user}/${userId}`,
          method: "GET"
        })
      }),


    EditAgent: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "PUT",
        body,
      }),
    }),



    deleteByIdAgent: builder.query<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.user}/${userId}`,
        method: "DELETE",
      }),
    }),

    getAllTransactions: builder.query<CommonResponseType & { data: any }, { page?: number; limit: number, id?: any, propertyType?: string }>({
      query: ({ page, limit, id,propertyType }) => {
        let url = `${END_POINTS.user}/${id}/${END_POINTS.trackRecord}?&page=${page}&limit=${limit}&propertyType=${propertyType}`;
        return {
          url: url,
          method: "GET",
        };
      },
    }),


  }),
});

export const {
  usePostAddAgentMutation,
  useLazyGetAllAgentQuery,
  useLazyGetSpecificAgentByIdQuery,
  useEditAgentMutation,
  useLazyDeleteByIdAgentQuery,
  useLazyGetAllTransactionsQuery

} = authApi;
