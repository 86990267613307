import { Box, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Dispatch, SetStateAction } from "react";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  link: string;
  setIsLoading:Dispatch<SetStateAction<boolean>>;
  isLoading:boolean
};

const DocumentViewer = ({ open, setOpen, link ,setIsLoading,isLoading}: props) => {
  return (
    <Modal className="modal docs_modal" open={open} closeAfterTransition>
      <Box className="modalBox_body">
        <Box>
          <Button className="close_btn" onClick={() => setOpen(false)}
            style={{padding:"3px",background:"red", borderRadius:"25px"}}>
            <CloseIcon />
          </Button>
          {isLoading && <Box width={"100%"} height={"100%"} position={"absolute"} padding="250px">
            <figure style={{marginInline:"-7%"}}>
              <img width={90} src="/static/images/loader.gif" alt="loading" />
            </figure>
          </Box>}
          <iframe 
          onLoad={()=>{setIsLoading(false)}}
          src={`https://docs.google.com/viewer?url=${link}&embedded=true`} height="550px" width="100%"></iframe>
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentViewer;
