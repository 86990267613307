import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { generateResponsiveStyle } from "../utils/modalStyle";
import { Label } from "@mui/icons-material";
import { useAddSettingsMutation } from "../services/settings";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { errorToast, successToast, warnToast } from "../helpers";
import Loader from "../helpers/constants/Loader";
import { useEditByIdMutation, useLazyGetByIdListingQuery } from "../services/listing";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    listingType: string;
    id: string;
    getListing: () => void;
};

const AddTokenModal = ({ open, setOpen, listingType, id, getListing }: props) => {
    const style = generateResponsiveStyle();
    const [token, setToken] = useState<string>();
    const [duration, setDuration] = useState<string>();
    const [addSettings] = useAddSettingsMutation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editToken] = useEditByIdMutation();
    const [getById] = useLazyGetByIdListingQuery();


    const postData = async () => {
        if (duration === "") {
            warnToast("Please enter Duration of Listing")
            return;
        }
        if (token === "") {
            warnToast("Please enter Tokens")
            return;
        }
        setIsLoading(true);
        let body = {
            token: Number(token),
            // validity: Number(duration),
            listingType: Number(listingType)
        }
        try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const res = await addSettings(encryptedBody).unwrap();
            setIsLoading(false)
            if (res?.statusCode === 200) {
                successToast("Record added successfully")
            }
            setOpen(false);
            setToken('')
        } catch (error: any) {
            setIsLoading(false)
            setOpen(false)
            setToken('')
            errorToast(error?.data?.message || "Something went wrong")
        }

    }


    const fetchData = async () => {
        try {
            const res = await getById({ id: id }).unwrap();
            if (res?.statusCode === 200) {
                setToken(res?.data?.token)
            }
        } catch (error: any) {

        }
    }

    const EditData = async () => {

        if (token === "") {
            warnToast("Please enter Tokens")
            return;
        }
        setIsLoading(true);
        let body = {
            token: Number(token),
            // validity: Number(duration),
            listingType: Number(listingType)
        }
        console.log(body, "body");

        try {
            const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
            const res = await editToken({ body: encryptedBody, id: id }).unwrap();
            setIsLoading(false)
            if (res?.statusCode === 200) {
                successToast("Record updated successfully")
                setToken('')
                getListing()
            }
            setOpen(false)
        } catch (error: any) {
            setIsLoading(false)
            setOpen(false)
            setToken('')
            errorToast(error?.data?.message || "Something went wrong")
        }

    }


    useEffect(() => {
        if (open === true) {
            fetchData()
        }

    }, [open])

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Loader isLoad={isLoading} />
                <div className="modal_title">
                    <Typography id="modal-modal-title" component="h2">Edit Details</Typography>
                    <CloseIcon className="cross_icn_logout" onClick={() => { setOpen(false); setToken('') }} />
                </div>
                <div className="modal_body">
                    {/* <div className="control_group">
                        <TextField
                        hiddenLabel
                        type="text"
                        name="title"
                        inputProps={{ maxLength: 4 }}
                        variant="outlined"
                        fullWidth
                        value={duration}
                        placeholder="Enter duration"
                        className="text_field"
                        onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^\d*\.?\d*$/;
                            if (regex.test(value)) {
                                setDuration(value);
                            }
                        }}
                    />
                    </div> */}
                    <div className="control_group">
                        <TextField
                            hiddenLabel
                            type="text"
                            name="title"
                            inputProps={{ maxLength: 4 }}
                            variant="outlined"
                            fullWidth
                            value={token}
                            placeholder="Enter tokens"
                            className="text_field"
                            onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^\d*\.?\d*$/;
                                if (regex.test(value)) {
                                    setToken(value);
                                }
                            }}
                        />
                    </div>

                    <div className="form_btn">
                        <Button
                            className="btn btn_primary"
                            onClick={() => {
                                setOpen(false);
                                EditData()
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default AddTokenModal;
