import React from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";

const ManageCompanySubscription = () => {
  const navigate = useNavigate();

  function createData(
    subscription:string,
    name: string,
    
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    validity:string,
    postedAds: string,
    earnings: string
  ) {
    return {
      subscription,
      name,
     
      userName,
      phone,
      email,
      planName,
      planPrice,
      validity,
      postedAds,
      earnings,
    };
  }

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const rows = [
   
    createData(
      "Monthly Special",
      "John Doe",
      "JohnDoe",
      "+971056734321",
      "johndoe@gmail.com",
      "Yearly",
      "$50/mo",
      "1 month",
      "10",
      "$299.90"
    ),
    createData(
      "Yearly Premium",
      "Jane Smith",
      "JaneSmith",
      "+971056734321",
      "janesmith@gmail.com",
      "Monthly",
      "$499/yr",
      "1 year",
      "10",
      "$49.90"
    ),
  ];

  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Company Subscription</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-company-subscription/add")}
              >
                Add Company Subscription
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Subscription Name</TableCell>
                  <TableCell>Subscription type</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Validity</TableCell>
                 
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.subscription}</TableCell>
                    <TableCell>{row.planName}</TableCell>
                    <TableCell>
                      <Typography variant="h6" fontSize={14}>
                        {row.planPrice}
                      </Typography>
                       
                    </TableCell>
                   
                    <TableCell>{row.validity}</TableCell>
                   
                    <TableCell>
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-company-subscription/details")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate("/manage-company-subscription/add", {
                              state: { type: "edit" },
                            })
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </div>
  );
};

export default ManageCompanySubscription;
