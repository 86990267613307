import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,

} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetSpecificUserByIdQuery } from "../../services/users";
import WarnModal from "../../components/WarnModal";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/constants/Loader";
import { UserData } from "../../types/General";
import moment from "moment";

const SavedSearchUser = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [limit, setLimit] = useState<number>(10);
  const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / limit);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const fetchDataById = async () => {
    try {
      const response = await fetchApi({ userId: _id, detailsType: 11 }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data?.alert || []);
        setTotalCount(response?.data?.count)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlert = (alertType: number) => {
    switch (alertType) {
      case 1:
        return "Hourly";
      case 2:
        return "Daily";
      case 3:
        return "Every 3 days";
      case 4:
        return "Weekly";
      case 5:
        return "Off";
      default:
        return null;  // or handle the default case differently if needed
    }
  };


  useEffect(() => {
    if (_id) { fetchDataById(); }
  }, [])
  return (
    <Card className="cards">
      <Loader isLoad={isLoading} />
      <TableContainer className="table_container" style={{ marginTop: 0 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Alert Type</TableCell>
              <TableCell>Search</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ByIdDetails?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No saved search found
                </TableCell>
              </TableRow>
            ) : (
              ByIdDetails?.map((row: any, index: number) => (
                <TableRow key={row._id}>
                  <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                  <TableCell>{handleAlert(row?.alertType) || "-"}</TableCell>
                  <TableCell>{row?.search || "-"}</TableCell>
                  <TableCell>
                    {moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

      </TableContainer>
      {ByIdDetails?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={ByIdDetails}
          limit={limit}
          setLimit={setLimit}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}

    </Card>
  );
};

export default SavedSearchUser;
