

import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";
import { useNavigate } from "react-router-dom";

function App() {


  useEffect(() => {
    async function registerServiceWorker() {
      if ("serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js"
          );
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        } catch (error) {
          console.error("Service Worker registration failed:", error);
        }
      }
    }

    async function setToken() {
      try {
        const registration = await navigator.serviceWorker.ready; // Ensures service worker is ready
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log("Error setting FCM token:", error);
      }
    }

    registerServiceWorker().then(setToken); // Register service worker first, then set FCM token

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("Event from the service worker:", event);
          toast(
            <div style={{ height: "100%" }}>
              <div style={{ color: "#820000" }}>
                {event?.data?.notification?.title}
              </div>
              <div style={{ color: "#1d1d1d", paddingTop: "10px" }}>
                {event?.data?.notification?.body}
              </div>
            </div>
          );
        } catch (e) {
          console.error("Error handling service worker message:", e);
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((notificationPerm) => {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
              if (window) {
                window.location.reload();
              }
            }
          };
        });
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

    return <Routing />;
}

export default App;
