import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card, Chip, ClickAwayListener, IconButton, InputAdornment, MenuItem, Paper, Popper, Select, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetAllNotificatiosnQuery,
  useDeleteByIdNotificationMutation,
} from "../../services/notifications";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { Clear } from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ManageNotifications = () => {
  const navigate = useNavigate();
  const [row1, setRow1] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  const [deleteNotificationById] = useDeleteByIdNotificationMutation();
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [type, setType] = useState("");
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });


  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRow1([]);
  };

  const formatDate = (isoString: string | number | Date) => {
    const date = new Date(isoString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };



  const { data, isError, isSuccess, isLoading } = useGetAllNotificatiosnQuery({
    page: page, limit: limit, role: type, search: debouncedSearchTerm, range: {
      from: range?.from ? moment(range?.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : "", to: range?.to ? moment(range?.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : ""
    }
  })

  const handleDeleteNotification = async (userId: any) => {
    try {
      const response = await deleteNotificationById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Notification deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;
      setRow1(res?.notification)
      setTotalCount(res?.count);
    }
  }, [isSuccess, data]);


  return (
    <div>
      <div className="main_layout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Notifications</h1>
        </div>
        <Loader isLoasd={isLoading} />
        <Card className="cards">
          <Box className="cards_header" style={{ marginBottom: 20 }}>
            <Box className="cards_header_left">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </Box>
            <Box className="cards_header_right">
              <div className="control_group">
                <Select
                  className="childFilter"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Receiver
                  </MenuItem>
                  <MenuItem value="4">User</MenuItem>
                  <MenuItem value="3">Agent </MenuItem>
                  <MenuItem value="5">Company</MenuItem>
                </Select>
              </div>
              <div className="control_group">
                <TextField
                  // label="Select Date Range"
                  variant="outlined"
                  hiddenLabel
                  className="text_field-range "
                  fullWidth
                  onClick={handleClick}
                  value={getFormattedRange()}
                  placeholder="Select Date Range"
                  InputProps={{
                    readOnly: true,
                    endAdornment: range?.from ? (
                      <InputAdornment position="end" style={{ marginLeft: -50 }}>
                        <IconButton
                          onClick={() => {
                            setRange({
                              from: undefined,
                              to: undefined
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Popper
                  open={open1}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  disablePortal
                  style={{
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                      <div
                        style={{
                          padding: 8,
                          borderRadius: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: "8px",
                          }}
                        >
                          <Chip
                            label="Last Week"
                            variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastWeek")}
                          />
                          <Chip
                            label="Last Month"
                            variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastMonth")}
                          />
                          <Chip
                            label="Last Year"
                            variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                            onClick={() => handleChipClick("lastYear")}
                          />
                        </div>

                        <DayPicker
                          className="react-day-picker"
                          mode="range"
                          selected={range}
                          onSelect={handleSelect}
                          // numberOfMonths={10}
                          month={month}
                          onMonthChange={handleMonthChange}
                        // styles={{
                        //   months: { display: "flex", gap: "16px" },
                        //   month: { margin: 0 },
                        // }}
                        />
                      </div>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
              <Button
                className="btn btn_danger"
                onClick={() => {
                  setType("");
                  setRange({ from: undefined, to: undefined })

                }}
              >
                Clear filters
              </Button>
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                <AddIcon /> Add Notification
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Sent To</TableCell>
                  <TableCell>Date</TableCell>

                  <TableCell>Title</TableCell>
                  <TableCell>Messages</TableCell>

                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row1.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No notification found
                    </TableCell>
                  </TableRow>
                ) : (
                  row1.map((row: any, index: any) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell>
                        {row?.role == "3"
                          ? "Agent"
                          : row?.role == "4"
                            ? "User"
                            : row?.role == "4"
                              ? "Company"
                              : "All"}
                      </TableCell>
                      <TableCell> {formatDate(row?.createdAt)}</TableCell>
                      <TableCell>{row?.title}</TableCell>

                      <TableCell>{row?.message}</TableCell>

                      <TableCell align="center">
                        <Box className="table_actions">
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {
        row1?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={row1}
            page={page}
            limit={limit}
            setLimit={setLimit}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )
      }
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteNotification(selectedId)}
        name="Notification"
      />
    </div>
  );
};

export default ManageNotifications;
