import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,

} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetSpecificUserByIdQuery } from "../../services/users";
import WarnModal from "../../components/WarnModal";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/constants/Loader";
import { UserData } from "../../types/General";
import moment from "moment";




const WishListUser = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [ByIdDetails, setByIdDetails] = useState<any>([]);
  const [limit, setLimit] = useState<number>(10);
  const [fetchApi, { isLoading }] = useLazyGetSpecificUserByIdQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / limit);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const fetchDataById = async () => {
    try {
      const response = await fetchApi({ userId: _id, detailsType: 10 }).unwrap();
      if (response.statusCode === 200) {
        setByIdDetails(response?.data?.reels || []);
        setTotalCount(response?.data?.count)
      }
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    if (_id) { fetchDataById(); }
  }, [])
  return (
    <Card className="cards">
      <Loader isLoad={isLoading} />
      <TableContainer className="table_container" style={{ marginTop: 0 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Property </TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ByIdDetails?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Wishlist is empty
                </TableCell>
              </TableRow>
            ) : (
              ByIdDetails?.map((row: any, index: number) => (
                <TableRow key={row.connectionId}>
                  <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                  <TableCell>
                    <figure className="user_img">
                      <img style={{ borderRadius: 0 }} src={row?.propertyId?.images?.[0] || `/static/images/house.jpg`} alt="" />
                    </figure>
                  </TableCell>
                  <TableCell>{row?.propertyId?.name || "-"}</TableCell>
                  <TableCell>{moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a') || "-"}</TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton onClick={() => navigate("/wishlist-details", { state: { data: row?.propertyId, id: _id } })}>
                        <VisibilityIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

      </TableContainer>
      {ByIdDetails?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={ByIdDetails}
          limit={limit}
          setLimit={setLimit}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}

    </Card>
  );
};

export default WishListUser;
