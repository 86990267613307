import { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useLazyGetTokenListingQuery } from "../../services/users";
import { errorToast } from "../../helpers";
import Pagination from "../../components/Pagination";

const TokenHistoryAgent = () => {

  const [data, setData] = useState<any[]>([]);
  const [TokenListing, { isLoading }] = useLazyGetTokenListingQuery();
  const { _id } = useParams();

  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [selectedId, setSelectedId] = useState<number>(0);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setData([]);
  };
  const fetchApi = async (userId: any) => {
    try {
      const response = await TokenListing({ userId: _id }).unwrap();
      setData(response?.data?.historyDetails || []);
      setTotalCount(response?.data?.count);
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  useEffect(() => {
    fetchApi(_id);
  }, [_id]);

  return (
    <>
      <Card className="cards">
      <TableContainer className="table_container" style={{ marginTop: 0 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Spends</TableCell>
                <TableCell>Tokens Used</TableCell>
                <TableCell>Property Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 || !data[0].tokenhistories ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No token history available
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((item: any, index: number) => (
                  <TableRow key={item?.tokenhistories?._id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>
                      {new Date(item?.tokenhistories?.createdAt || "-").toLocaleDateString() || "-"}
                    </TableCell>
                    <TableCell>
                      {item?.tokenhistories?.propertyType === 1 ? "Sell" : "Rent"}
                    </TableCell>
                    <TableCell>{item?.tokenhistories?.propertyName || "-"}</TableCell>
                    <TableCell>{item?.tokenhistories?.token || "-"}</TableCell>
                    <TableCell>{item?.tokenhistories?.propertyName || "-"}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card> {data?.length > 0 ? (
        <Pagination
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          module={data}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TokenHistoryAgent;
