import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
  s3Location?: string
};



interface CategoryResponse extends CommonResponseType {
  data: any;

}

interface QueryParams {
  userId?: any;
  page?: number;
  limit?: number;
}

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddCategory: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.category,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GETCATEGORY"]
    }),
    getAllCategory: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string, limit?: number, type?: string, parentId?: string }>({
      query: ({ page, search, limit, type, parentId }) => {
        let url = `${END_POINTS.category}?type=${type}&parentId=${parentId}&page=${page}&search=${search}&limit=${limit}`;

        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 300,
      providesTags: ["GETCATEGORY"]
    }),



    getSpecificCategoryById: builder.query<CategoryResponse, QueryParams>({
      query: ({ userId }) => {
        let url = `${END_POINTS.category}/${userId}`;

        return {
          url,
          method: 'GET',
        };
      },

    }),


    EditCategory: builder.mutation<
      CommonResponseType & { data: CommonBody },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GETCATEGORY"]
    }),



    deleteByIdCategory: builder.mutation<CommonResponseType & { data: any }, { userId: any }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.category}/${userId}`,
        method: "DELETE",
      }),

      invalidatesTags: ["GETCATEGORY"]
    }),
  }),
});

export const {
  usePostAddCategoryMutation,
  useGetAllCategoryQuery,
  useLazyGetAllCategoryQuery,
  useLazyGetSpecificCategoryByIdQuery,
  useEditCategoryMutation,
  useDeleteByIdCategoryMutation

} = authApi;
