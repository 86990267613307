import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,

} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyGetChatListingQuery } from "../../services/users";
import { format } from 'date-fns';
import WarnModal from "../../components/WarnModal";
import { useDeleteChatByIdMutation } from "../../services/chat";
import { errorToast, successToast } from "../../helpers";
import Pagination from "../../components/Pagination";
import { useLazyPopertyChatByIdQuery } from "../../services/property";
import Loader from "../../helpers/constants/Loader";

const Messages = () => {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [deleteChatById] = useDeleteChatByIdMutation();
  const [data, setData] = useState<any[]>([]);

  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  const [TokenListing, { isLoading }] = useLazyGetChatListingQuery();

  const [page, setPage] = useState(1);
  const formatDateTime = (dateString: string | number | Date) => {
    if (!dateString) return "-";
    const date = format(new Date(dateString), 'yyyy-MM-dd');
    const time = format(new Date(dateString), 'HH:mm:ss');
    return `${date}, Time: ${time}`;
  };

  const fetchApi = async (userId: any) => {
    try {
      const response = await TokenListing({ userId: _id }).unwrap();
      setData(response?.data?.chats || []);
      setTotalCount(response?.data?.count);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    fetchApi(_id);
  }, [_id]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setData([]);
    fetchApi(_id);
  };
  const handleDeleteChat = async (userId: any) => {
    try {
      const response = await deleteChatById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Chat deleted successfully");
        fetchApi(page);
      }

    } catch (error: any) {
      console.error(error, "9999999999");
      errorToast(error?.data?.message || "");
    }
  };
  return (
    <Card className="cards">
      <Loader isLoad={isLoading} />
      <TableContainer className="table_container" style={{ marginTop: 0 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Property</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No chat found
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={row.connectionId}>
                  <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                  <TableCell>{row?.senders?.name || "-"}</TableCell>
                  <TableCell>{row?.receivers?.name || "-"}</TableCell>
                  <TableCell>{formatDateTime(row?.createdAt) || "-"}</TableCell>
                  <TableCell>{row?.properties?.name || "-"}</TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton onClick={() => navigate(`/manage-users/property/details/UserChatInbox/${row?.connectionId}`)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedId(row?.connectionId);
                          setOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

      </TableContainer>
      {data?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={data}
          limit={limit}
          setLimit={setLimit}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteChat(selectedId)}
        name="Chat"
      />
    </Card>
  );
};

export default Messages;
