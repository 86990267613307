import React, { useState, useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";

import {  useAddFaqMutation, useGetAllFaqQuery } from "../../services/manageCms";
import { errorToast, successToast } from "../../helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../helpers/constants/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface ContactSupport {
  email?: string;
  phoneNo?: string;
  // Add other properties if needed
}


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [cookiesPolicy, setCookiesPolicy] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [aboutUs, setAbout] = useState<string>("");
  const [boostListing, setBoostListing] = useState<string>("")
  const [qualityScore, setQualityScore] = useState<string>("")
  const [verifyListing, setVerifyListing] = useState<string>("")
  const [superAgentCreteria, setSuperAgentCreteria] = useState<string>("")
  const [customerAgreement, setCustomerAgreement] = useState<string>("")
  const [termConditionsCrm, setTermConditionsCrm] = useState<string>("")
  const [termConditionsPremium, setTermConditionsPremium] = useState<string>("")
  const [termUseLive, setTermUseLive] = useState<string>("")
  const [termConditionsLive, setTermConditionsLive] = useState<string>("")
  const [termConditionsExpertMaster, setTermConditionsExpertMaster] = useState<string>("")

  const [inputdata, setInputdata] = useState<any>();
  const [dialCode, setPhoneCode] = useState("+91");
  console.log(dialCode, "code")
  const [ContactSupport, setContactSupport] = useState<ContactSupport>({});

  const [error1, setError1] = useState<boolean>(false);
  const [error2, setError2] = useState<boolean>(false);
  const [error3, setError3] = useState<boolean>(false);
  const [error4, setError4] = useState<boolean>(false);
  const [error5, setError5] = useState<boolean>(false);
  const [error6, setError6] = useState<boolean>(false);
  const [error7, setError7] = useState<boolean>(false);
  const [error8, setError8] = useState<boolean>(false);
  //new
  const [error9, setError9] = useState<boolean>(false);
  const [error10, setError10] = useState<boolean>(false);
  const [error11, setError11] = useState<boolean>(false);
  const [error12, setError12] = useState<boolean>(false);
  const [error13, setError13] = useState<boolean>(false);
  const [error14, setError14] = useState<boolean>(false);

  const [value, setValue] = useState(0);
  const [CountryCode, setCountryCode] = useState<string>("+971");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  console.log(dialCode, "dialCode");

  const [updateCms, { isLoading }] = useAddFaqMutation();
  const [cmsData, setCmsData] = useState<any>(null);


  const formik = useFormik({
    initialValues: {
      termsAndConditions: cmsData?.termsAndConditions || "",
      email: inputdata?.email || "",
      phoneNo: (inputdata?.phoneNo) || "",
      privacyPolicy: cmsData?.privacyPolicy || "",
      cookiesPolicy: cmsData?.cookiesPolicy || "",
      boostListing: cmsData?.boostListing || "",
      qualityScore: cmsData?.qualityScore || "",
      verifyListing: cmsData?.verifyListing || "",
      superAgentCreteria: cmsData?.superAgentCreteria || "",
      customerAgreement: cmsData?.customerAgreement || "",
      termConditionsCrm: cmsData?.termConditionsCrm || "",
      termConditionsPremium: cmsData?.termConditionsPremium || "",
      termUseLive: cmsData?.termUseLive || "",
      termConditionsLive: cmsData?.termConditionsLive || "",
      termConditionsExpertMaster: cmsData?.termConditionsExpertMaster || "",


      // dialCode: cmsData?.contactSupport?.dialCode ||dialCode,
      aboutUs: cmsData?.aboutUs || ""
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Enter a valid email address"
        )
        .matches(
          /^[^\uD800-\uDBFF\uD800-\uDBFF]+$/, // Regex to disallow emojis
          "Emojis are not allowed in the email address"
        ),
      phoneNo: Yup.string().required("Phone is required").matches(
        /^[0-9]{9,15}$/,
        "Enter a valid phone number!"
      ),
      // privacyPolicy: Yup.string().min(9,"Privacy Policy is required").required("Privacy Policy is required"),


      // about: Yup.string().min(9).required("About is required"),
      // termsAndConditions: Yup.string().min(9).required("Terms & Conditions are required"),
    }),


    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (privacyPolicy.length < 9) {
        setError1(true);
        errorToast("Privacyt Policy is required")
        return;
      }
      else {
        setError1(false);
      }
      if (termsAndConditions.length < 9) {
        setError2(true);
        errorToast("Terms & Conditions is required")
        return;
      }
      else {

        setError2(false);
      }
      if (aboutUs.length < 9) {
        errorToast("About is required ")
        setError3(true);
        return;
      }
      else {
        setError3(false);
      }
      if (cookiesPolicy.length < 5) {
        errorToast("Cookies Policy is required ")
        setError4(true);
        return;
      }
      else {
        setError4(false);
      }
      if (boostListing.length < 5) {
        errorToast("Boost listing policy is required ")
        setError5(true);
        return;
      }
      else {
        setError5(false);
      }
      if (qualityScore.length < 5) {
        errorToast("Quality score policy is required ")
        setError6(true);
        return;
      }
      else {
        setError6(false);
      }
      if (verifyListing.length < 5) {
        errorToast("Verify listing policy is required ")
        setError7(true);
        return;
      }
      else {
        setError7(false);
      }
      if (superAgentCreteria.length < 5) {
        errorToast("Super agent criteria is required ")
        setError8(true);
        return;
      }
      else {
        setError8(false);
      }

      if (customerAgreement.length < 5) {
        errorToast("Customer agreement is required ")
        setError9(true);
        return;
      }
      else {
        setError9(false);
      }
      if (termConditionsCrm.length < 5) {
        errorToast("Terms for CRM providers are required ")
        setError10(true);
        return;
      }
      else {
        setError10(false);
      }
      if (termConditionsPremium.length < 5) {
        errorToast("Terms for Single use premium are required")
        setError11(true);
        return;
      }
      else {
        setError11(false);
      }
      if (termUseLive.length < 5) {
        errorToast("Live Viewings Terms of Use are required")
        setError12(true);
        return;
      }
      else {
        setError12(false);
      }
      if (termConditionsLive.length < 5) {
        errorToast("Live Viewings Terms and Conditions are required")
        setError13(true);
        return;
      }
      else {
        setError13(false);
      }
      if (termConditionsExpertMaster.length < 5) {
        errorToast("PF Expert Terms and Conditions are required ")
        setError14(true);
        return;
      }
      else {
        setError14(false);
      }

      let body = {
        termsAndConditions: termsAndConditions,
        contactSupport: {
          email: values.email,
          phoneNo: values.phoneNo,
          dialCode: dialCode
        },
        privacyPolicy: privacyPolicy,
        cookiesPolicy: cookiesPolicy,
        aboutUs: aboutUs,
        boostListing: boostListing,
        qualityScore: qualityScore,
        verifyListing: verifyListing,
        superAgentCreteria: superAgentCreteria,
        customerAgreement: customerAgreement,
        termConditionsCrm: termConditionsCrm,
        termConditionsPremium: termConditionsPremium,
        termUseLive: termUseLive,
        termConditionsLive: termConditionsLive,
        termConditionsExpertMaster: termConditionsExpertMaster,

      };
      console.log("body", body);
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateCms(encryptedBody).unwrap();

        if (response?.statusCode === 200) {
          successToast("Manage CMS Updated Successfully");
        }
      } catch (error: any) {
        console.error("this is an error");
      }
    },

  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      "phoneNo",
      phone?.replace(country?.dialCode, "")
    );
    setPhoneCode(country?.dialCode.includes("+") ? "" : "+" + country?.dialCode);
  };


  const { data, isError, isLoading: load, isSuccess } = useGetAllFaqQuery({});


  useEffect(() => {
    if (isSuccess && data) {
      const cmsData = data?.data;
      setCmsData(cmsData)
      formik.setValues({
        termsAndConditions: cmsData?.termsAndConditions || "",
        email: cmsData?.contactSupport?.email || "",
        phoneNo: cmsData?.contactSupport?.phoneNo || "",
        privacyPolicy: cmsData?.privacyPolicy || "",
        cookiesPolicy: cmsData?.cookiesPolicy || "",
        boostListing: cmsData?.boostListing || "",
        qualityScore: cmsData?.qualityScore || "",
        verifyListing: cmsData?.verifyListing || "",
        superAgentCreteria: cmsData?.superAgentCreteria || "",
        customerAgreement: cmsData?.customerAgreement || "",
        termConditionsCrm: cmsData?.termConditionsCrm || "",
        termConditionsPremium: cmsData?.termConditionsPremium || "",
        termUseLive: cmsData?.termUseLive || "",
        termConditionsLive: cmsData?.termConditionsLive || "",
        termConditionsExpertMaster: cmsData?.termConditionsExpertMaster || "",

        // Set the dialCode or default to dialCode state
        aboutUs: cmsData?.aboutUs || "",
      });
      setPrivacyPolicy(cmsData?.privacyPolicy || "");
      setCookiesPolicy(cmsData?.cookiesPolicy || "")
      setTermsAndConditions(cmsData?.termsAndConditions || "");
      setBoostListing(cmsData?.boostListing || "");
      setQualityScore(cmsData?.qualityScore || "");
      setVerifyListing(cmsData?.verifyListing || "");
      setSuperAgentCreteria(cmsData?.superAgentCreteria || "");
      setCustomerAgreement(cmsData?.customerAgreement || "");
      setTermConditionsCrm(cmsData?.termConditionsCrm || "");
      setTermConditionsPremium(cmsData?.termConditionsPremium || "");
      setTermUseLive(cmsData?.termUseLive || "");
      setTermConditionsLive(cmsData?.termConditionsLive || "");
      setTermConditionsExpertMaster(cmsData?.termConditionsExpertMaster || "");
      setAbout(cmsData?.aboutUs || "");
      setInputdata(cmsData?.contactSupport);
      setPhoneCode(cmsData?.contactSupport?.dialCode || dialCode);

    }
  },[isSuccess,data])


  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
          <Loader isLoad={isLoading || load} />
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Term & Conditions" {...a11yProps(2)} />
              <Tab label="Cookies Policy" {...a11yProps(3)} />
              <Tab label="About" {...a11yProps(4)} />
              <Tab label="Boost Listing content" {...a11yProps(5)} />
              <Tab label="Quality score content" {...a11yProps(6)} />
              <Tab label="Verify listing content" {...a11yProps(7)} />
              <Tab label="Super agent criteria" {...a11yProps(8)} />

              <Tab label="Customer Agreement" {...a11yProps(9)} />
              <Tab label="For CRM Providers" {...a11yProps(10)} />
              <Tab label="Single Use Premium" {...a11yProps(11)} />
              <Tab label="Live Viewings Terms of Use" {...a11yProps(12)} />
              <Tab label="Live Viewings Terms and Conditions" {...a11yProps(13)} />
              <Tab label="GP Expert" {...a11yProps(14)} />


            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={(event) => formik.setFieldValue("email", event.target.value)}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.email && formik.errors.email ? String(formik.errors.email) : ""
                      }
                    />


                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      value={dialCode + formik.values.phoneNo}
                      country={"ae"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phoneNo && typeof formik.errors.phoneNo === 'string' ? (
                      <h6 className="err_msg">
                        {formik.errors.phoneNo}
                      </h6>
                    ) : (
                      ""
                    )}


                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                {error1 ? (
                  <h6 className="err_msg">
                    Privacy Policy is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                {error2 ? (
                  <h6 className="err_msg">
                    Terms & Condition is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={cookiesPolicy}
                  setContent={setCookiesPolicy}
                />
                {error4 ? (
                  <h6 className="err_msg">
                    Cookies policy is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={aboutUs}
                  setContent={setAbout}
                />
                {error3 ? (
                  <h6 className="err_msg">
                    About  is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={boostListing}
                  setContent={setBoostListing}
                />
                {error5 ? (
                  <h6 className="err_msg">
                    Boost listing policy is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={qualityScore}
                  setContent={setQualityScore}
                />
                {error6 ? (
                  <h6 className="err_msg">
                    Quality score policy is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={verifyListing}
                  setContent={setVerifyListing}
                />
                {error7 ? (
                  <h6 className="err_msg">
                    Verify listing policy is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={8}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={superAgentCreteria}
                  setContent={setSuperAgentCreteria}
                />
                {error8 ? (
                  <h6 className="err_msg">
                    Super agent criteria is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>

            {/* new */}
            <CustomTabPanel value={value} index={9}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={customerAgreement}
                  setContent={setCustomerAgreement}
                />
                {error9 ? (
                  <h6 className="err_msg">
                    Customer agreement is required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={10}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termConditionsCrm}
                  setContent={setTermConditionsCrm}
                />
                {error10 ? (
                  <h6 className="err_msg">
                    Terms for CRM providers are required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={11}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termConditionsPremium}
                  setContent={setTermConditionsPremium}
                />
                {error11 ? (
                  <h6 className="err_msg">
                    Terms for Single use premium are required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={12}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termUseLive}
                  setContent={setTermUseLive}
                />
                {error12 ? (
                  <h6 className="err_msg">
                    Live Viewings Terms of Use are required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={13}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termConditionsLive}
                  setContent={setTermConditionsLive}
                />
                {error13 ? (
                  <h6 className="err_msg">
                    Live Viewings Terms and Conditions are required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={14}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termConditionsExpertMaster}
                  setContent={setTermConditionsExpertMaster}
                />
                {error14 ? (
                  <h6 className="err_msg">
                    PF Expert Terms and Conditions are required
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>

              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default ManageCms;
