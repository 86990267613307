import { Box, Button, Card, CardContent, FormControl, Grid, IconButton, NativeSelect, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";

import { useLazyGetSpecificCompanyPropertyByIdQuery } from "../../services/users";
import { errorToast, successToast } from "../../helpers/toast";
import { useDeleteByIdPropertyMutation } from "../../services/property";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../helpers/constants/Loader";
const CompanyProperty = () => {

  const { _id } = useParams();
  const navigate = useNavigate();
  const [deletePropertyById] = useDeleteByIdPropertyMutation();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [allDetailsById, setAllDetailsById] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [propertyType, setPropertyType] = useState<number>(0)
  const [propertyStatus, setPropertyStatus] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10);
  let totalPages = Math.ceil(totalCount / limit);
  console.log("alllwdxed", allDetailsById);
  const [page, setPage] = useState(1);
  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setAllDetailsById([]);
  };
  // const [editCompanybyId] = useEditUserMutation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [AllDetails, { isLoading }] = useLazyGetSpecificCompanyPropertyByIdQuery();

  const fetchAllDataById = async () => {
    try {
      const response = await AllDetails({ userId: _id, listingType: propertyType === 0 ? "" : propertyType, propertyStatus: propertyStatus === 0 ? "" : propertyStatus }).unwrap();
      if (response.statusCode === 200) {
        setAllDetailsById(response?.data?.propertyDetails || []);
        setTotalCount(response?.data?.count || 0);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteProperty = async (userId: any) => {
    try {
      const response = await deletePropertyById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Property deleted successfully");
        fetchAllDataById();
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  useEffect(() => {
    if (_id) {
      fetchAllDataById()
    }

  }, [propertyStatus, propertyType]);




  return (
    <>


      <Card className="cards">

        <div>
          <FormControl>
            <div style={{ marginRight: "10px" }}>
              <NativeSelect
                value={propertyStatus}
                onChange={(e) => setPropertyStatus(Number(e.target.value))}
                inputProps={{
                  name: "report",
                  id: "uncontrolled-native",
                }}
              >
                <option value={0} disabled>Select Property Status</option>
                <option value={1}>Sold Properties</option>
                <option value={2}>Rental Properties</option>
                <option value={3}>Live Properties</option>

              </NativeSelect>
            </div>
          </FormControl>
          <FormControl>
            <div>
              <NativeSelect
                value={propertyType}
                onChange={(e) => setPropertyType(Number(e.target.value))}
                inputProps={{
                  name: "report",
                  id: "uncontrolled-native",
                }}
              >
                <option value={0} disabled>Select Property Type</option>
                <option value={1}>Standard</option>
                <option value={2}>Featured</option>
                <option value={3}>Premium</option>
              </NativeSelect>
            </div>
          </FormControl>
        </div>
        <Loader isLoad={isLoading} />
        <TableContainer className="table_container">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Property Status</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Agent</TableCell>
                <TableCell>Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {allDetailsById.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No property found
                  </TableCell>
                </TableRow>
              ) : (
                allDetailsById.map((property: any, index: number) => (
                  <TableRow key={property?._id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>
                      <figure className="user_img">
                        <img
                          style={{ borderRadius: 0 }}
                          src={property?.images[0] || "/static/images/house.jpg"}
                          alt=""
                        />
                      </figure>
                    </TableCell>
                    <TableCell>{property?.name || "-"}</TableCell>
                    <TableCell>{property?.address || "-"}</TableCell>
                    <TableCell>{property?.propertyStatus === 1 ? "Rented" : property?.propertyStatus === 2 ? "Sold" : "Live"}</TableCell>
                    <TableCell>{property?.listingType === 1 ? "Standard" : property?.listingType === 2 ? "Featured" : "Premium"}</TableCell>
                    <TableCell>{property?.ownerName || "-"}</TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate(`/company/property-listing/details/${property?._id}`)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => {
                          setOpen(true);
                          setSelectedId(property?._id);
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {allDetailsById?.length > 0 ? (
        <Pagination
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          module={allDetailsById}
          page={page}
          onPageChange={onPageChange1}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDeleteProperty(selectedId)}
        name="Property"
      />
    </>
  );
};

export default CompanyProperty;
