import emptySplitApi from "../utils/rtk";

import { END_POINTS } from "../helpers";


type CommonResponseType = {
    statusCode: number;
    message: string;
    s3Location?: string
};


export const listingApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getAllListing: builder.query<CommonResponseType & { data: any }, {  }>({
            query: ({  }) => {
                let url = `${END_POINTS.listing}`;

                return {
                    url: url,
                    method: "GET",
                };
            },
        }),

        getByIdListing: builder.query<CommonResponseType & { data: any }, { id: string }>({
            query: ({ id }) => {
                let url = `${END_POINTS.listing}/${id}`;
                return {
                    url: url,
                    method: "GET",
                };
            },
        }),

        editById: builder.mutation<CommonResponseType & { data: any }, { id: string, body: any }>({
            query: ({ body, id }) => {
                let url = `${END_POINTS.listing}/${id}`;
                return {
                    url: url,
                    method: "PUT",
                    body
                };
            },
        }),


    }),
});

export const {
    useLazyGetAllListingQuery,
    useLazyGetByIdListingQuery,
    useEditByIdMutation,
} = listingApi;
