// export const API_URL = "https://getpropapi.appgrowthcompany.com/api/v1/Admin";
// export const media_url="https://getpropapi.appgrowthcompany.com/api/v1/Upload/";
export const API_URL =
  "https://getpropmicroapi.appgrowthcompany.com/admin/api/v1/Admin";
export const media_url =
  "https://getpropmicroapi.appgrowthcompany.com/common/api/v1/Upload/";

export const END_POINTS = {
  login: "login",
  mediaUpload: "uploadFile",
  get_user: "getProfile",
  logout: "logout",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  uploadFiles: "uploadFiles",
  //user
  user: "user",
  getDetails: "getDetails",
  details:"details",
  //category
  category: "category",
  ////profile
  updateProfile: "updateProfile",
  getProfile: "getProfile",
  //cms
  addCms: "cms",
  getCms: "cms",
  //ads
  ads: "ads",
  //banners
  banner: "banner",
  //subscription
  subscription: "subscription",
  //settings
  settings: "setting",
  //property
  property: "property",
  dashboard: "dashboard",
  analytics: "analytics",
  boostProfile: "boostProfile",
  propertyExport: "property/export",
  amenitiesListing: "amenities/listing",
  propertyId: "propertyId",
  //chat
  chats: "chats",
  notification: "notification",
  usersListing: "usersListing",
  //
  export: "user/export",
  manageRevenue: "manageRevenue",
  notifyList: "notifyList",
  notifyExport: "notifyExport",
  receivedNotification: "receivedNotifications",
  amenities: "amenities",
  propertyType: "propertyType",
  propertyTypeListing: "propertyType",
  reels: "reels",
  listing: "listing",
  propertyCondition: "propertyCondition",
  feedback:"feedback-form",
  reply:"reply",
  adminRequests:"admin-requests",

  
  leadsInsightPerformance: "performance-insights",
  performanceInsightsOverview:"user/performance-insights/overview",
  reportProperty:"property/reportProperty",
  propertyreportPropertyById:"/property/reportPropertyById",
  trackRecord:"trackRecord",
  contactUs:"contact-us",
  
};
