

import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetSpecificChatByIdQuery } from "../../../services/chat";
import { errorToast } from "../../../helpers";
import MainContainer from "../../../layout/MainContainer";


const UserEmailInboxDetails = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log('state: ', state);



    return (
        <div>
            <div className="main_layout">
                <div className="dashboard" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3>Email Details</h3>
                    <div style={{ fontSize: 30, alignItems: 'center', textAlign: 'center' }}>
                        Agent Name: {state?.agent?.name || "-"}
                    </div>
                    <Button
                        className="btn btn_primary"
                        onClick={() => {
                            navigate(`/manage-users/details/${userId}`, { state: 2 });
                        }}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <CardContent sx={{ p: 1 }}>
                        <div className="main">
                            <div className="heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="userDetail" style={{ display: 'flex' }}>
                                    <figure>
                                        <img src={state?.agent?.image ? state?.agent?.image : `/static/images/user_placeholder.png`} alt="image" />
                                    </figure>
                                    <div className="username">
                                        <h3>{state?.message || "-"}</h3>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default UserEmailInboxDetails;






