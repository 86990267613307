import React, { useEffect, useLayoutEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ClearIcon from '@mui/icons-material/Clear';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Tabs,
  Tab,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Popper,
  ClickAwayListener,
  Paper,
  Chip,
  InputAdornment,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Autocomplete as GoogleAutoComplete } from '@react-google-maps/api';

import {
  useLazyGetPendingCompanyQuery,
} from "../../services/users";


import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import SearchBar2 from "../../components/SearchBar2";
import { isValidInput } from "../../utils/validation";
import Loader from "../../helpers/constants/Loader";
import AddIcon from "@mui/icons-material/Add";
import { useLazyExportPropertyCsvQuery } from "../../services/dashboard";
import { useLazyGetAllCategoryQuery } from "../../services/category";
import { Drafts, FilterAlt, FilterAltOff } from "@mui/icons-material";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import {  useGetPendingPropertyQuery, useLazyGetpropertYTypeListingQuery, useDeleteByIdPropertyMutation } from "../../services/property";
import { errorToast, successToast } from "../../helpers";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const ManageProperty = () => {
  const navigate = useNavigate();
  let role = 5;
  const { state } = useLocation();
  const [rows1, setRows1] = useState<any>([]);
  const [exportCsv] = useLazyExportPropertyCsvQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount1] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [fetchAllCategory] = useLazyGetAllCategoryQuery();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [propertyType, setPropertyType] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("")
  let totalPages = Math.ceil(totalCount / limit);
  const [showFilter, setShowFilters] = useState<boolean>(false);
  const [properties, setProperties] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [propertyTypeList] = useLazyGetpropertYTypeListingQuery();
  const [categories, setCategories] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
  };
  const onPageChange1 = (newPage: number) => {
    setPage(newPage);
    setRows1([]);
  };
  const [deletePropertyById] = useDeleteByIdPropertyMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [companies, setCompanies] = useState<any>([]);
  const [getApprovedCompany] = useLazyGetPendingCompanyQuery();
  const [location, setLocation] = useState("");
  const [listingType, setListingType] = useState<number | string>("")
  const [autocomplete, setAutocomplete] = useState(null); // state to store the autocomplete for the google places api
  const [latlng, setLatlng] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 })
  const [maxPrice, setMaxPrice] = useState<number | string>("");
  const [minPrice, setMinPrice] = useState<number | string>("")
  const [open1, setOpen1] = useState(false); // Control calendar visibility
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedChip, setSelectedChip] = useState("")
  const [month, setMonth] = useState(new Date());
  const [range, setRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });


  const handleClose = () => {
    setOpen1(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const getFormattedRange = () => {
    if (range?.from && range?.to) {
      return `${moment(range.from).format("MM/DD/YYYY")} - ${moment(
        range.to
      ).format("MM/DD/YYYY")}`;
    } else if (range?.from) {
      return moment(range.from).format("MM/DD/YYYY");
    }
    return "";
  };

  const handleSelect = (selectedRange: any) => {
    if (selectedRange === undefined) {
      setRange({ from: undefined, to: undefined });
    } else {
      setRange(selectedRange);
    }
  };

  const handleChipClick = (value: any) => {
    if (value === selectedChip) {
      setSelectedChip("");
      setRange({ from: undefined, to: undefined });
      setMonth(moment().toDate());
      return;
    }
    setSelectedChip(value);

    let newRange = { from: moment().toDate(), to: moment().toDate() };
    const today = moment();

    if (value === "lastMonth") {
      newRange = {
        from: moment().subtract(1, "months").startOf("month").toDate(),
        to: moment().subtract(1, "months").endOf("month").toDate(),
      };
    } else if (value === "lastWeek") {
      newRange = {
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: today.toDate(),
      };
    } else if (value === "lastYear") {
      newRange = {
        from: moment().subtract(1, "years").startOf("year").toDate(),
        to: moment().subtract(1, "years").endOf("year").toDate(),
      };
    }
    setRange(newRange);
    setMonth(newRange.from);
  };

  const handleMonthChange = (newMonth: any) => {
    setMonth(newMonth);
  };



  const fetchCompanies = async () => {
    try {
      const response = await getApprovedCompany({
        page: 1, search: "", limit: 100, range: { from: "", to: "" }, isAdminAprooved: 2
      }).unwrap();
      if (response.statusCode === 200) {
        setCompanies(response?.data?.user || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategory({
        limit: 50,
        page: 1,
        search: "",
        type: "subcategory",
        parentId: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  const fetchPropertyType = async () => {
    try {
      const response = await propertyTypeList({}).unwrap();
      if (response?.statusCode === 200) {
        setProperties(response?.data?.propertyType || []);
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  



  const { data, isError, isLoading: load, isSuccess } = useGetPendingPropertyQuery({
    page: page,
    search: debouncedSearchTerm?.trim(),
    limit: limit,
    propertyType: propertyType,
    category: category,
    price: price,
    companyId: selectedCompany,
    agentId: state?.agentId ? state?.agentId : "",
    propertyName: state?.type ? state?.type : null,
    isDraftSaved: Boolean(searchParams?.get("draft")),
    minPrice: Number(minPrice) || "",
    maxPrice: Number(maxPrice) || "",
    startDate: range?.from ? String(range?.from) : "",
    endDate: range?.to ? String(range?.to) : "",
    listingType: Number(listingType) || "",
    offerStatus: value === 0 ? 1 : value === 1 ? 3 : value === 2 ? 2 : 2,
    lat: Number(latlng?.lat),
    long: Number(latlng?.lng),

  })


  const handleDeleteProperty = async (userId: any) => {
    try {
      const response = await deletePropertyById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Property deleted successfully");
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({}).unwrap();
      console.log("eugded", res);

      if (res?.statusCode === 200) {
        window.open(res?.data);
        successToast("CSV exported successfully");
      } else {
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };

  // loading fucntion for the google places api
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  // Function to listen the google places api changes
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();
      console.log("place :", place);

      if (place) {
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();
        setLocation(place?.formatted_address);
        setLatlng({
          lat: lat,
          lng: lng
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      const res = data?.data;      
      setRows1(res?.property || []);
      setTotalCount1(res?.count);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    fetchCategories();
    fetchPropertyType();
    fetchCompanies();
  }, []);

  useLayoutEffect(() => {
    if (state) {
      setValue(state)
    }
  }, [])

  return (
    <div>
      <Loader isLoad={load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            Manage Property Listing{" "}
            {Boolean(searchParams.get("draft")) ? `(Drafts)` : ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar2
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />

              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => setShowFilters(!showFilter)}
                >
                  {showFilter ? <FilterAltOff /> : <FilterAlt />}
                  {showFilter ? "Hide filters" : "Show filters "}
                </Button>
                {Boolean(searchParams.get("draft")) ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => {
                      setSearchParams("");
                    }}
                  >
                    <MapsHomeWorkIcon />
                    Properties
                  </Button>
                ) : (
                  <Button
                    className="btn btn_primary"
                    onClick={() => {
                      setSearchParams("draft=true");
                    }}
                  >
                    <Drafts />
                    Drafts
                  </Button>
                )}
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/property-listing/add")}
                >
                  <AddIcon />
                  Add Property
                </Button>
                <Button className="btn btn_primary" onClick={handleExportCsv}>
                  <FileDownloadIcon /> Export CSV
                </Button>
              </Box>
            </Box>
            {showFilter ? (
              <Box>
                <div className="Filters_div">
                  <div className="childFilter">
                    <Select
                      className="childFilter"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={propertyType}
                      onChange={(e) => setPropertyType(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Property Type
                      </MenuItem>
                      {properties?.length > 0
                        ? properties?.map((it: any) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                  </div>
                  <div className="childFilter">
                    {/* <Typography className="custom_label">Categories</Typography> */}
                    <Select
                      className="childFilter"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select category
                      </MenuItem>
                      {categories?.length > 0
                        ? categories?.map((it: any) => (
                          <MenuItem key={it?._id} value={it?._id}>
                            {it?.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                  </div>

                  <div className="childFilter">
                    {/* <Typography className="custom_label">Price</Typography> */}
                    <Select
                      className="childFilter"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Price
                      </MenuItem>

                      <MenuItem value="3">Low to high </MenuItem>
                      <MenuItem value="4">High to low </MenuItem>
                    </Select>
                  </div>
                  <div className="childFilter">
                    <Select
                      className="childFilter"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={listingType}
                      onChange={(e) => setListingType(Number(e.target.value))}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Listing
                      </MenuItem>

                      <MenuItem value="1">Standard </MenuItem>
                      <MenuItem value="3">Premium</MenuItem>
                      <MenuItem value="2">Featured</MenuItem>
                    </Select>
                  </div>

                </div>
                <div className="Filters_div">
                  <div className="childFilter">
                    <Select
                      className="childFilter"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value=""
                      // onChange={(e) => setPrice(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Status
                      </MenuItem>

                      <MenuItem value="1">Active </MenuItem>
                      <MenuItem value="2">Inactive</MenuItem>

                    </Select>
                  </div>
                  <div className="childFilter">
                    {/* <Typography className="custom_label">Company</Typography> */}
                    <Autocomplete
                      options={companies || []}
                      getOptionLabel={(option: any) => option?.name || ""}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Company" variant="outlined" />
                      )}
                      value={companies?.find((it: any) => it?._id === selectedCompany) || null}
                      onChange={(event, newValue) => {
                        setSelectedCompany(newValue?._id || "");
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option?._id === value?._id
                      }
                    />
                  </div>
                  <div className="childFilter control_group">
                    <GoogleAutoComplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                      options={{
                        types: ["political"],
                        componentRestrictions: { country: "ae" },
                      }}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        name="address"
                        placeholder="Address"
                        value={location}
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={(val) => {
                          if (val.target.value === " " || val.target.value === ".") {
                            setLatlng({
                              lat: 0,
                              lng: 0,
                            });
                          } else {
                            setLocation(val.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </GoogleAutoComplete>
                    {/* </Select> */}
                  </div>
                  <div className="childFilter control_group  w_25 ">
                    <TextField
                      // label="Select Date Range"
                      variant="outlined"
                      hiddenLabel
                      className="text_field-range "
                      fullWidth
                      onClick={handleClick}
                      value={getFormattedRange()}
                      placeholder="Select Date Range"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <Popper
                    open={open1}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    disablePortal
                    style={{
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Paper elevation={3} style={{ position: "relative", zIndex: 1 }}>
                        <div
                          style={{
                            padding: 8,
                            borderRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              padding: "8px",
                            }}
                          >
                            <Chip
                              label="Last Week"
                              variant={selectedChip === "lastWeek" ? "filled" : "outlined"}
                              onClick={() => handleChipClick("lastWeek")}
                            />
                            <Chip
                              label="Last Month"
                              variant={selectedChip === "lastMonth" ? "filled" : "outlined"}
                              onClick={() => handleChipClick("lastMonth")}
                            />
                            <Chip
                              label="Last Year"
                              variant={selectedChip === "lastYear" ? "filled" : "outlined"}
                              onClick={() => handleChipClick("lastYear")}
                            />
                          </div>

                          <DayPicker
                            className="react-day-picker"
                            mode="range"
                            selected={range}
                            onSelect={handleSelect}
                            // numberOfMonths={10}
                            month={month}
                            onMonthChange={handleMonthChange}
                          // styles={{
                          //   months: { display: "flex", gap: "16px" },
                          //   month: { margin: 0 },
                          // }}
                          />
                        </div>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>


                </div>
                <div className="Filters_div">
                  <div className="childFilter">
                    <TextField
                      className="text_field"
                      hiddenLabel
                      name="startingPrice"
                      placeholder="Minimum Price"
                      value={minPrice}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          setMinPrice(Number(value));
                        }
                      }}
                      fullWidth
                      inputProps={{
                        maxLength: 10,
                      }}

                    />
                  </div>
                  <div className="childFilter">
                    <TextField
                      className="text_field"
                      hiddenLabel
                      name="startingPrice"
                      placeholder="Maximum Price"
                      value={maxPrice}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          setMaxPrice(Number(value));
                        }
                      }}
                      fullWidth
                      inputProps={{
                        maxLength: 10,
                      }}

                    />
                  </div>
                  {propertyType !== "" || category !== "" || price !== "" || selectedCompany !== "" ||
                    maxPrice !== "" || minPrice !== "" || listingType !== "" || (latlng.lat !== 0 || latlng.lng !== 0) || location !== ""
                    ? (
                      <Button
                        className="btn btn_primary"
                        style={{ backgroundColor: "black" }}
                        onClick={() => {
                          setPropertyType("");
                          setCategory("");
                          setPrice("");
                          setSelectedCompany("");
                          setMaxPrice("");
                          setMinPrice("");
                          setListingType("");
                          setRange({ from: undefined, to: undefined })
                          setLatlng({ lat: 0, lng: 0 });
                          setLocation("");
                        }}
                      >
                        Clear filters
                      </Button>
                    ) : ("")}
                </div>
              </Box>
            ) : ("")}

            <Box className="custom_tabs">

              <Tabs
                style={{ marginTop: "20px" }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Published" {...a11yProps(0)} />
                <Tab label="Under Offer" {...a11yProps(1)} />
                <Tab label="Unpublished" {...a11yProps(2)} />
                {/* <Tab label="Claimed Properties" {...a11yProps(3)} /> */}
              </Tabs>

              <CustomTabPanel value={value} index={0}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Reference Id</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Owner Name</TableCell>
                        <TableCell>Property Category</TableCell>
                        <TableCell>Property Type</TableCell>
                        {/* <TableCell>Request</TableCell> */}

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows1.length == 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No property found
                          </TableCell>
                        </TableRow>
                      ) : (
                        rows1?.map((row: any, i: any) => (
                          <TableRow key={row?.i}>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              <figure onClick={() =>
                                navigate(
                                  `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`, { state: { tab: 0 } }
                                )
                              } className="user_img">
                                <img
                                  style={{ borderRadius: 0 }}
                                  src={
                                    row?.images[0] || `/static/images/product1.png`
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.name || "-"}</TableCell>
                            <TableCell>{row?.referenceId || "-"}</TableCell>
                            <TableCell>{row?.price || "-"}</TableCell>
                            <TableCell>{row?.users?.name || "-"}</TableCell>
                            <TableCell>{row?.category?.name || "-"}</TableCell>
                            <TableCell>{row?.propertyTypes?.name || "-"}</TableCell>
                            {/* <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={receiver}
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) => {
                              handleReceiverChange(value, row?._id);
                            }}
                          
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="1">Pending</MenuItem>
                            <MenuItem value="2">Approve</MenuItem>
                            <MenuItem value="3">Reject</MenuItem>
                          </Select>
                        </TableCell> */}

                            < TableCell >
                              <Box className="table_actions">
                                {!Boolean(searchParams.get("draft")) && (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`, { state: { tab: 0 } }
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/property-listing/edit/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Reference Id</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Owner Name</TableCell>
                        <TableCell>Property Category</TableCell>
                        <TableCell>Property Type</TableCell>
                        {/* <TableCell>Request</TableCell> */}

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows1.length == 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No property found
                          </TableCell>
                        </TableRow>
                      ) : (
                        rows1.map((row: any, i: any) => (
                          <TableRow key={row?.i}>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              <figure onClick={() =>
                                navigate(
                                  `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`, { state: { tab: 1 } }
                                )
                              } className="user_img">
                                <img
                                  style={{ borderRadius: 0 }}
                                  src={
                                    row?.images[0] || `/static/images/product1.png`
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.name || "-"}</TableCell>
                            <TableCell>{row?.referenceId || "-"}</TableCell>
                            <TableCell>{row?.price || "-"}</TableCell>
                            <TableCell>{row?.users?.name || "-"}</TableCell>
                            <TableCell>{row?.category?.name || "-"}</TableCell>
                            <TableCell>{row?.propertyTypes?.name || "-"}</TableCell>
                            {/* <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={receiver}
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) => {
                              handleReceiverChange(value, row?._id);
                            }}
                          
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="1">Pending</MenuItem>
                            <MenuItem value="2">Approve</MenuItem>
                            <MenuItem value="3">Reject</MenuItem>
                          </Select>
                        </TableCell> */}

                            <TableCell>
                              <Box className="table_actions">
                                {!Boolean(searchParams.get("draft")) && (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`, { state: { tab: 1 } }
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/property-listing/edit/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S.No</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Property Name</TableCell>
                        <TableCell>Reference Id</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Owner Name</TableCell>
                        <TableCell>Property Category</TableCell>
                        <TableCell>Property Type</TableCell>
                        {/* <TableCell>Request</TableCell> */}

                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows1.length == 0 ? (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            No property found
                          </TableCell>
                        </TableRow>
                      ) : (
                        rows1.map((row: any, i: any) => (
                          <TableRow key={row?.i}>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              <figure onClick={() =>
                                navigate(
                                  `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`, { state: { tab: 2 } }
                                )
                              } className="user_img">
                                <img
                                  style={{ borderRadius: 0 }}
                                  src={
                                    row?.images[0] || `/static/images/product1.png`
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.name || "-"}</TableCell>
                            <TableCell>{row?.referenceId || "-"}</TableCell>
                            <TableCell>{row?.price || "-"}</TableCell>
                            <TableCell>{row?.users?.name || "-"}</TableCell>
                            <TableCell>{row?.category?.name || "-"}</TableCell>
                            <TableCell>{row?.propertyTypes?.name || "-"}</TableCell>
                            {/* <TableCell>
                          <Select
                            fullWidth
                            className="select_div reqst_Select"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={receiver}
                            // multiple
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(value: any) => {
                              handleReceiverChange(value, row?._id);
                            }}
                          
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="1">Pending</MenuItem>
                            <MenuItem value="2">Approve</MenuItem>
                            <MenuItem value="3">Reject</MenuItem>
                          </Select>
                        </TableCell> */}

                            <TableCell>
                              <Box className="table_actions">
                                {!Boolean(searchParams.get("draft")) && (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/property-listing/details/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`, { state: { tab: 2 } }
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/property-listing/edit/${row?._id}${Boolean(searchParams?.get("draft")) ? `?draft=true` : ""}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>



            </Box>


            {rows1?.length > 0 ? (
              <Pagination
                setPage={setPage}
                module={rows1}
                page={page}
                onPageChange={onPageChange1}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
              />
            ) : (
              ""
            )}
            <WarnModal
              open={open}
              setOpen={setOpen}
              handleDelete={() => handleDeleteProperty(selectedId)}
              name="Property"
            />
          </Box>
        </Card>
      </div >
    </div>
  );
};

export default ManageProperty;
