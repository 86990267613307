import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";


import CompanyInsights from "../../features/company/PerformanceInsights";
import LeadsInsight from "../../features/leadsInsight";
import PerformanceOverview from "../../features/performanceOverview";
import AgentInsights from "../../features/agentInsights";
import { useLazyGetPerformanceInsightsQuery } from "../../services/performace";
import { SuperAgentOverview } from "../../features/agentInsights/overview";
import moment from "moment";
import Loader from "../../helpers/constants/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ListingPerformanceDashboard = () => {
  const [filter, setFilter] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [pieChartData, setPieChartData] = useState<
    { label: string; value: number, color: string }[]
  >([]);
  const [majorLineChartData, setMajorLineChartData] = useState<
    { name: string; count: number, emailCount: number, inAppChatCount: number, whatAppCount: number, phoneCount: number }[]
  >([]);
  const [displayTextData, setDisplayTextData] = useState<any>({
    whatsapp: {},
    phone: {},
    email: {},
    inApp: {}
  });
  const convertPieChartPercent = (data: { label: string; value: number, color: string }[]) => {
    const total = data.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.value);
    }, 0);
    if (total === 0) return data
    return data?.map((data2) => ({
      label: data2?.label,
      value: ((data2?.value / total) * 100).toFixed(2) || 0,
      color: data2?.color
    }));
  };
  const [getData] = useLazyGetPerformanceInsightsQuery();
  const [leadsSelectedTime, setLeadsSelectedTime] = useState("3");
  const [leadsSelectedApp, setLeadsSelectedApp] = useState("");


  const getLeadsInsight = async ({ startDate, endDate }: any) => {
    try {
      setIsLoading(true)
      const response = await getData({
        leadsFilterType: leadsSelectedTime,
        leadType: leadsSelectedApp,
        type: 4,
        startDate: startDate
          ? moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: endDate
          ? moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",

      }).unwrap();
      setIsLoading(false)
      if (response?.statusCode == 200) {
        // #8884d8= #020302
        // #83a6ed= #575856 
        // 8dd1e1=6c997e
        // 8dd1e8=98a29c
        setPieChartData([
          { label: "Whatsapp", value: response?.data?.whatAppPercentage, color: "#020302", },
          { label: "Calls", value: response?.data?.phoneLeadsPercentage, color: "#575856" },
          {
            label: "In-App Chat",
            value: response?.data?.inAppChatLeadsPercentage,
            color: "#6c997e"
          },
          { label: "Email", value: response?.data?.emailLeadsPercentage, color: "#98a29c", },
        ]);
        setMajorLineChartData(response?.data?.graphData?.userData);
        setDisplayTextData({
          email: {
            emailLeads: response?.data?.emailLeads,
            emailLeadsNotreplied: response?.data?.emailLeadsNotreplied,
            emailLeadsReplied: response?.data?.emailLeadsReplied,
            emailLeadsResponseRate: response?.data?.emailLeadsResponseRate,
            emailLeadsResponseTime: response?.data?.emailLeadsResponseTime,
          },
          whatsapp: {
            whatsappLeads: response?.data?.whatsappLeads,
            whatAppNotreplied: response?.data?.whatAppNotreplied,
            whatAppReplied: response?.data?.whatAppReplied,
            whatAppResponseRate: response?.data?.whatAppResponseRate,
            whatAppResponseTime: response?.data?.whatAppResponseTime,
          },
          phone: {
            phoneLeads: response?.data?.phoneLeads,
            phoneLeadsAns: response?.data?.phoneLeadsAns,
            phoneLeadsAvg: response?.data?.phoneLeadsAvg,
            phoneLeadsCancel: response?.data?.phoneLeadsCancel,
            phoneLeadsUnans: response?.data?.phoneLeadsUnans,
          },
          inApp: {
            inAppChatLeads: response?.data?.inAppChatLeads,
            inAppChatLeadsPercentage: response?.data?.inAppChatLeadsPercentage,
            inAppChatLeadsNotreplied: response?.data?.inAppChatLeadsNotreplied,
            inAppChatLeadsResponseRate: response?.data?.inAppChatLeadsResponseRate,
            inAppChatLeadsResponseTime: response?.data?.inAppChatLeadsResponseTime,

          }
        });
      }
    } catch (error: any) {
      setIsLoading(false)
      console.log(error);
    }
  };
  const [agentData, setAgentData] = useState({
    leads: [],
    score: [],
    listing: [],
    agentsCount: {},
    totalAgentsPercentage: 0,
  });
  const getAgentsInsight = async ({ startDate, endDate }: any) => {
    setIsLoading(true)
    try {

      const response = await getData({
        type: 3,
        startDate: startDate
          ? moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: endDate
          ? moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      setIsLoading(false)
      if (response?.statusCode == 200) {
        setAgentData({
          leads: response?.data?.leads?.data,
          listing: response?.data?.liveListing?.data,
          score: response?.data?.qualityScore?.data,
          agentsCount: {
            total: response?.data?.totalAgents,
            super: response?.data?.superAgents,
            verified: response?.data?.verifiedAgents,
          },
          totalAgentsPercentage: response?.data?.totalAgentsPercentage,
        });
      }
    } catch (error: any) {
      setIsLoading(false)
      console.log(error);
    }
  };
  const [overallGraphData, setOverallGraphData] = useState({
    listingsData: [
      { name: "Standard", value: 0, percentage: 0 },
      { name: "Featured", value: 0, percentage: 0 },
      { name: "Premium", value: 0, percentage: 0 },
    ],
    listingsDataTotal: 0,
    impressionsData: [
      { name: "Standard", value: 0, percentage: 0 },
      { name: "Featured", value: 0, percentage: 0 },
      { name: "Premium", value: 0, percentage: 0 },
    ],
    impressionsDataTotal: 0,
    clicksData: [
      { name: "Standard", value: 0, percentage: 0 },
      { name: "Featured", value: 0, percentage: 0 },
      { name: "Premium", value: 0, percentage: 0 },
    ],
    clicksDataTotal: 0,
  });
  const getOverallInsight = async ({ startDate, endDate }: any) => {
    setIsLoading(true)
    try {
      const response = await getData({
        type: 1,
        startDate: startDate
          ? moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
        endDate: endDate
          ? moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
          : "",
      }).unwrap();
      setIsLoading(false)
      if (response?.statusCode == 200) {
        console.log(response);
        setOverallGraphData({
          listingsData: [
            {
              name: "Standard",
              value: response?.data?.standard,
              percentage:
                (response?.data?.standard /
                  (response?.data?.premium +
                    response?.data?.featured +
                    response?.data?.standard)) *
                100 || 0,
            },
            {
              name: "Featured",
              value: response?.data?.featured,
              percentage:
                (response?.data?.featured /
                  (response?.data?.premium +
                    response?.data?.featured +
                    response?.data?.standard)) *
                100 || 0,
            },
            {
              name: "Premium",
              value: response?.data?.premium,
              percentage:
                (response?.data?.premium /
                  (response?.data?.premium +
                    response?.data?.featured +
                    response?.data?.standard)) *
                100 || 0,
            },
          ],
          listingsDataTotal: response?.data?.totalListings,
          impressionsData: [
            {
              name: "Standard",
              value: response?.data?.standardImpressions,
              percentage:
                (response?.data?.standardImpressions /
                  (response?.data?.premiumImpressions +
                    response?.data?.featuredImpressions +
                    response?.data?.standardImpressions)) *
                100 || 0,
            },
            {
              name: "Featured",
              value: response?.data?.featuredImpressions,
              percentage:
                (response?.data?.featuredImpressions /
                  (response?.data?.premiumImpressions +
                    response?.data?.featuredImpressions +
                    response?.data?.standardImpressions)) *
                100 || 0,
            },
            {
              name: "Premium",
              value: response?.data?.premiumImpressions,
              percentage:
                (response?.data?.premiumImpressions /
                  (response?.data?.premiumImpressions +
                    response?.data?.featuredImpressions +
                    response?.data?.standardImpressions)) *
                100 || 0,
            },
          ],
          impressionsDataTotal: response?.data?.impressions,
          clicksData: [
            {
              name: "Standard",
              value: response?.data?.standardClicks,
              percentage:
                (response?.data?.standardClicks /
                  (response?.data?.standardClicks +
                    response?.data?.featuredClicks +
                    response?.data?.premiumClicks)) *
                100 || 0,
            },
            {
              name: "Featured",
              value: response?.data?.featuredClicks,
              percentage:
                (response?.data?.featuredClicks /
                  (response?.data?.standardClicks +
                    response?.data?.featuredClicks +
                    response?.data?.premiumClicks)) *
                100 || 0,
            },
            {
              name: "Premium",
              value: response?.data?.premiumClicks,
              percentage:
                (response?.data?.premiumClicks /
                  (response?.data?.standardClicks +
                    response?.data?.featuredClicks +
                    response?.data?.premiumClicks)) *
                100 || 0,
            },
          ],
          clicksDataTotal: response?.data?.listingClicks,
        });
      }
    } catch (error: any) {
      setIsLoading(false)
      console.log(error);
    }
  };
  return (
    <div>
      <div className="main_loyout">
        <Loader isLoad={isLoading}/>
        <div className="dashboard">
          <h1 className="mn_hdng">Performance Dashboard</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Overall Performance" {...a11yProps(0)} />
              <Tab label="Listing Performance" {...a11yProps(1)} />
              <Tab label="Agent insights" {...a11yProps(2)} />
              <Tab label="Leads insights" {...a11yProps(3)} />
              <Tab label="Competitive  Score" {...a11yProps(4)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <CompanyInsights
                getOverallInsight={getOverallInsight}
                graphData={overallGraphData}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <PerformanceOverview
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <AgentInsights
                getAgentsInsight={getAgentsInsight}
                agentData={agentData}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <LeadsInsight
                filter={leadsSelectedTime}
                setFilter={setLeadsSelectedTime}
                pieChartData={convertPieChartPercent(pieChartData)}
                majorLineChartData={majorLineChartData}
                getLeadsInsight={getLeadsInsight}
                setLeadsSelectedApp={setLeadsSelectedApp}
                displayTextData={displayTextData}
                leadsSelectedApp={leadsSelectedApp}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <SuperAgentOverview />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default ListingPerformanceDashboard;
