import { useLocation, useNavigate } from "react-router-dom";
import React, { useLayoutEffect } from "react";
import {
  Box,
  Button,
  Card,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import {
  Details,
} from "../../../features";
import Messages from "../../../features/users/Messages";
import SearchedListingDetails from "../../../features/users/SearchedListingDetails";
import ContactedAgents from "../../../features/users/ContactedAgentsDetails";
import WishListUser from "../../../features/users/Wishlist";
import SavedSearchUser from "../../../features/users/SavedSearchUser";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useLayoutEffect(() => {
    if (state) {
      setValue(state)
    }
  }, [])
  return (
    <div>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-users");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="User Details" {...a11yProps(0)} />
                <Tab label="Searched listings" {...a11yProps(1)} />
                <Tab label="Contacted Agents" {...a11yProps(1)} />
                <Tab label="Wishlist" {...a11yProps(1)} />
                <Tab label="Saved searches" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Details />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <SearchedListingDetails />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ContactedAgents />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <WishListUser />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <SavedSearchUser />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default UserDetails;
